import styled from "styled-components";

export default styled.div`
  .wrapper {
    &_album-cover {
      width: 25%;
      border: 1px solid var(--gray-20);
      border-radius: 5px;
      overflow: hidden;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
    &_album-intro-content {
      width: 75%;
      padding-right: 60px;
      @media only screen and (max-width: 768px) {
        width: 100%;
        padding-right: 0;
      }
    }
    &_back {
      margin-bottom: 50px;
      font-size: 1.25rem;
      color: var(--gray-40);
      cursor: pointer;
      path {
        fill: var(--gray-40);
      }
      @media only screen and (max-width: 768px) {
        margin-bottom: 10px;
        font-size: 1rem;
        svg {
          width: 32px;
        }
      }
    }
    &_album-title {
      font-size: 2rem;
      @media only screen and (max-width: 768px) {
        font-size: 1.25rem;
        margin-top: 24px;
      }
    }
    &_second-title {
      font-size: 1.25rem;
      color: var(--gray-40);
      @media only screen and (max-width: 768px) {
        font-size: 1.125rem;
        text-align: center;
      }
    }
    &_album-description {
      font-size: 1rem;
      color: var(--gray-80);
      line-height: 28px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
    }
    &_play-link {
      color: var(--spotify-color);
      font-size: 1.25rem;
      font-weight: bold;
      .wrapper_circle {
        border-radius: 100px;
        border: 1px solid;
      }
      svg {
        width: 20px;
        height: 20px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }
    &_buy-album {
      color: var(--primary-color);
      font-size: 1.25rem;
      font-weight: bold;
      @media only screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }
    &_more-albums {
      margin-top: 100px;
    }
  }
`;
