import { log } from "console";
import CustomLink from "../../customLink";
import Icon from "../../Icon/Icon";
import Wrapper from "./styles";

interface props {
  items: any;
  className: string;
}

function availableOn({ items, className }) {
  return (
    <Wrapper className={`${className}`}>
      <div className="available-wrapper ds-flex justify-between">
        <div className="ds-flex align-center">
          {items?.map((item) => (
            <CustomLink
              target="_blank"
              link={item?.link}
              key={item?.icon}
              className="ds-flex align-center"
            >
              <Icon className="ml-1" name={item?.icon} />
            </CustomLink>
          ))}
          <div>available on</div>
        </div>
      </div>
    </Wrapper>
  );
}

export default availableOn;
