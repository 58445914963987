import { useEffect } from "react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useConfig } from "../../../context/config";
import Card from "../../../components/card/card";

interface props {
  className: string;
  data: any;
}
const grid = () => {
  let result;
  if (isMobile) {
    result = "mb-4 col-1";
  } else if (isDesktop) {
    result = "col-3";
  } else {
    result = "col-2";
  }
  return result;
};
function List({ className, data }) {
  const { state, actions } = useConfig();
  return (
    <div className={className}>
      <div className="ds-flex mt-5 justify-between flex-wrap">
        {data?.map((card) => (
          <Card
            key={card.id}
            title={
              state.lang === "fa" ? card?.title?.rendered : card?.acf?.en_title
            }
            description={
              state.lang === "fa"
                ? card?.content?.rendered
                : card?.acf?.english_description
            }
            externalLink={card?.acf?.external_link}
            link={undefined}
            img={card?.acf?.image}
            className={grid()}
            data={card}
          />
        ))}
      </div>
    </div>
  );
}

export default List;
