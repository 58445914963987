import styled from "styled-components";

export default styled.div`
  .info {
    a {
      color: #332114;
      &:hover {
        color: #bb6f38;
      }
    }
  }
  .gallery {
    margin: 10px;
    width: 30%;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      width: 45%;
      margin: 5px;
    }
  }
  .gallery-container {
    flex-wrap: wrap;
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
`;
