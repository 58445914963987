import Axios from "axios";
import ENDPOINTS from "../config/endpoints";

const getBioInfo = async () => {
  const { data } = await Axios.get(ENDPOINTS.home.bio);
  return data;
};

const getRows = async () => {
  const { data } = await Axios.get(ENDPOINTS.home.rows);
  return data;
};

export { getBioInfo, getRows };
