import { isDesktop, isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "../../button";
import Modal from "./modal";
import Icon from "../../Icon/Icon";
import CustomLink from "../../customLink";
import LazyImage from "../../lazyImage";
import CardWrapper from "./styles";

interface props {
  data: any;
  className: string;
}

function PodcastCard({ data, className }) {
  const [longDescription, setLongDescription] = useState(null);

  const handleLongDescriptionModalClose = () => {
    setLongDescription(false);
    document.body.removeEventListener("click", handleLongDescriptionModalClose);
  };
  const handleLongDescriptionModal = (e) => {
    if (e.target.innerText !== "پخش این قسمت") {
      setLongDescription(true);
      setTimeout(() => {
        document.body.addEventListener(
          "click",
          handleLongDescriptionModalClose
        );
      }, 1000);
    } else {
      console.log(data);
      console.log(data?.acf);

      window.location.href = data?.acf?.play_link;
    }
  };

  return (
    <CardWrapper className={`${className}`}>
      <div
        onClick={(e) => handleLongDescriptionModal(e)}
        aria-hidden="true"
        className={`wrapper ds-flex align-center ml-2 mr-2 ${
          isMobile ? "flex-column pl-2 pr-2 pt-2 pb-2 mb-4" : ""
        }`}
      >
        <div className={`ds-flex width-100 ${isMobile ? "align-center" : ""}`}>
          <div className="wrapper_cover">
            <LazyImage
              isLazy
              src={data?.acf?.image}
              alt={data?.title?.rendered}
            />
          </div>
          <div className="wrapper_content">
            <h3 className="wrapper_title">{data?.title?.rendered}</h3>
            <div
              className="wrapper_description"
              dangerouslySetInnerHTML={{
                __html:
                  data?.acf?.podcast_short_description?.length > 10
                    ? `${data?.acf?.podcast_short_description.substring(
                        0,
                        isDesktop ? 160 : 60
                      )}`
                    : data?.acf?.podcast_short_description,
              }}
            />
            {!isMobile && (
              <div className="ds-flex justify-between align-center wrapper_info">
                <button
                  type="button"
                  onClick={(e) => handleLongDescriptionModal(e)}
                >
                  مشاهده توضیحات
                </button>
                {!isMobile && (
                  <CustomLink
                    target="_blank"
                    className="wrapper_link ds-flex align-center"
                    link={data?.acf?.play_link}
                  >
                    <div>پخش این قسمت</div>
                    <Icon name="playOrange" />
                  </CustomLink>
                )}
              </div>
            )}
          </div>
        </div>
        {!isDesktop && (
          <div className="ds-flex justify-between align-center width-100 mt-3">
            <button
              type="button"
              onClick={(e) => handleLongDescriptionModal(e)}
            >
              مشاهده توضیحات
            </button>
            <CustomLink
              className="wrapper_link wrapper_text ds-flex align-center"
              link={data?.acf?.play_link}
            >
              <div>پخش این قسمت</div>
              <Icon name="playOrange" />
            </CustomLink>
          </div>
        )}
      </div>
      {longDescription === true && (
        <Modal
          title={data?.title?.rendered}
          data={data}
          className={undefined}
          closeModal={handleLongDescriptionModalClose}
        />
      )}
    </CardWrapper>
  );
}
PodcastCard.defaultProps = {
  data: null,
  className: null,
};

export default PodcastCard;
