interface props {
  className?: string;
}

function SmallLogo({ className }) {
  return (
    <div className={className}>
      <svg
        width="86"
        height="80"
        viewBox="0 0 86 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.24">
          <line y1="26.5" x2="86" y2="26.5" stroke="white" />
          <line y1="35.0714" x2="86" y2="35.0714" stroke="white" />
          <line y1="43.6429" x2="86" y2="43.6429" stroke="white" />
          <line y1="52.2143" x2="86" y2="52.2143" stroke="white" />
          <line y1="60.7857" x2="86" y2="60.7857" stroke="white" />
        </g>
        <path
          d="M60.3665 50.8726C64.6015 51.0842 67.6205 46.8946 69.5494 43.6361C70.1364 42.5781 70.6396 41.3932 71.0589 40.1659C71.3944 39.1079 71.0589 39.1079 70.4719 39.9543C69.7171 40.8853 69.0462 42.2395 67.956 43.0013C67.0335 43.6784 65.7336 43.6361 64.895 42.832C64.0145 42.0279 64.2661 40.7161 64.937 39.9543C66.3207 38.4308 67.2432 38.5155 69.0043 38.8963C69.9268 39.0656 70.4719 37.923 70.1364 37.2882C69.5494 36.1033 68.0399 36.2303 67.0335 36.5688C65.3982 37.1189 64.4338 38.7271 63.7629 40.1236C63.1339 41.3508 62.6727 42.5781 62.1276 43.89C61.876 44.5248 62.0018 44.7364 61.3728 44.7364C59.402 44.7364 58.3118 43.5514 58.3118 39.9966C57.599 41.5201 56.6765 43.0859 55.8379 44.6517C55.8379 48.672 57.3893 50.7456 60.3665 50.8726Z"
          fill="white"
        />
        <path
          d="M58.3119 39.9966C58.4377 37.2035 58.9828 33.6064 59.3602 30.898C59.5699 29.2899 59.0667 29.967 58.7312 30.7711C57.8926 32.5908 56.9282 34.5374 56.6766 36.4418C56.2154 39.1925 55.8799 43.636 55.8799 44.6517C55.8799 46.5137 54.5801 48.1641 52.4416 52.523C51.603 54.2157 50.7643 56.4586 52.6932 57.8128C53.1544 58.1091 53.7414 58.4476 54.3704 58.9131C56.0477 59.8865 55.7541 60.6482 54.9574 62.1294C53.2802 65.1763 50.0515 66.8268 46.697 67.0384C39.4429 67.5039 38.4366 61.7908 40.7008 55.8662C41.7911 52.9039 42.7974 50.3647 44.0973 47.487C44.7262 46.0905 44.265 46.0482 43.5102 47.3601C41.4556 50.9149 39.5268 54.7659 38.185 58.7862C36.3819 64.2876 36.1303 73.09 44.1392 73.4285C47.6614 73.4285 50.4708 71.4819 52.777 68.9427C54.7059 66.7845 56.1315 64.1184 57.1379 60.9444C58.0604 58.1091 59.2764 54.6389 56.5508 52.4807C55.1252 51.3804 54.622 50.9149 55.5864 49.0951C55.9638 48.418 56.425 47.8256 56.8443 47.1062C57.3056 45.9635 58.2281 41.9009 58.3119 39.9966Z"
          fill="white"
        />
        <path
          d="M45.083 18.7886C45.083 18.9156 45.5443 19.2965 46.5087 19.9736C46.8022 20.1852 47.0538 20.4391 47.3054 20.6507C47.6408 20.8623 47.8924 20.9892 48.0601 20.9892C48.6891 20.9892 49.5696 19.9736 50.7018 17.9846C51.163 18.2808 51.7081 18.704 52.421 19.3388C52.8822 19.8043 53.2596 20.0159 53.4692 20.0159C53.8047 20.0159 54.3498 19.5504 55.0626 18.577C55.8174 17.6037 56.1948 16.7573 56.1948 16.0802C56.1948 15.4454 55.8174 14.8107 55.0626 14.1336C54.3498 13.4988 53.7628 13.1602 53.2596 13.1602C53.1338 13.1602 52.7564 13.6257 52.1694 14.5144C51.7081 15.1492 51.2888 15.7417 50.9114 16.3765C50.6599 15.9109 50.1986 15.4454 49.4858 14.9376C48.773 14.4298 48.2698 14.1759 47.9343 14.1759C47.7247 14.1759 47.2215 14.8953 46.3829 16.3341C45.5023 17.7307 45.083 18.577 45.083 18.7886Z"
          fill="white"
        />
        <path
          d="M51.9781 27.0373C50.8274 24.8891 48.9781 24.3976 47.53 26.6533C47.2405 27.1214 47.0775 27.673 46.7476 28.3107C46.5839 28.7777 46.2521 29.2038 46.0884 29.6708C45.4336 31.5386 46.5355 32.8832 48.4168 32.2318C48.877 32.1008 48.835 32.1012 49.1318 32.4794C49.7677 33.2779 49.5206 33.7879 48.8552 34.4286C47.5248 35.7523 46.7781 36.6899 46.4047 37.1587C45.7827 37.9683 45.3259 38.4801 44.8746 39.6268L47.1701 38.3793C47.2897 37.6588 48.4133 36.6755 49.4127 35.8626C49.7875 35.563 50.2046 35.3054 50.579 34.9636C51.7847 33.768 52.5245 32.0263 52.6356 30.3325C52.7092 29.1469 52.4901 28.0485 51.9781 27.0373Z"
          fill="white"
        />
        <path
          d="M47.1701 38.3793C46.1583 37.7534 45.6201 38.5622 44.8746 39.6268C43.4184 40.9516 42.0876 42.2329 40.969 43.8086C38.6502 47.2147 35.7305 49.0179 33.6403 49.7557C32.9738 50.2694 31.4819 52.314 33.6994 51.7443C38.1731 50.2237 39.7944 48.6012 42.1985 45.3637C43.1104 44.1283 43.6912 43.4038 46.3889 45.0305C47.1477 45.4893 47.3978 45.3179 47.8962 44.7633C48.4781 44.1657 49.2652 43.0584 49.4705 42.5488C49.7173 41.9964 49.7574 41.7845 49.2918 41.2807C48.7839 40.735 48.1509 40.2751 47.6846 39.6867C47.3878 39.3084 47.2175 39.0137 47.1701 38.3793Z"
          fill="white"
        />
        <path
          d="M33.6995 51.7444C35.4088 50.5866 36.8606 48.7541 38.017 46.7125C39.3386 44.3732 41.2308 40.1245 37.9155 39.8152C35.7741 39.4954 33.9034 41.374 32.744 43.0771C31.8329 44.397 30.9641 45.7589 30.0953 47.1208C29.7227 47.6743 29.8065 47.6736 29.1345 47.5525C26.785 47.4039 24.7378 48.2684 22.9465 49.6384C21.4889 50.7939 20.1577 52.0329 19.0381 53.4817C17.711 55.1862 16.5953 57.1004 15.4374 58.9727C12.5408 63.4419 9.23068 68.5918 4.68524 71.5097C2.01602 73.1837 1.85672 74.1584 5.23801 72.3935C10.0806 69.8963 13.8957 64.9535 16.8332 60.357C19.0265 56.9944 21.7602 53.0768 26.3331 53.3328C29.5631 53.4736 31.7402 53.0735 33.6995 51.7444ZM31.4366 47.0667C31.9759 46.3848 32.4305 45.6191 32.9697 44.9372C33.7587 44.0415 34.5893 43.1031 35.8894 43.134C36.2672 43.173 36.6453 43.2543 37.1083 43.4618C37.571 43.627 37.9918 43.7926 37.8285 44.3019C37.6648 44.7689 37.333 45.195 37.0005 45.5365C35.5461 47.0728 33.7913 47.8077 31.7779 47.7408C30.8132 47.707 30.8974 47.7486 31.4366 47.0667Z"
          fill="white"
        />
        <path
          d="M46.1719 5.61886C46.0881 6.12668 45.9623 6.54987 45.7946 7.01538C45.6688 7.43857 45.3753 8.11567 44.9979 8.91973C44.6205 9.72379 44.4108 10.1893 44.327 10.2739C44.0335 10.7818 43.7819 10.9934 43.5303 10.9934C43.1948 10.7818 42.9432 10.5279 42.7336 10.5279C41.7692 10.5279 40.1339 11.2473 38.247 12.9823C36.402 14.7174 35.2698 16.4102 35.2698 16.9603C35.2698 17.4258 35.3956 17.7221 35.6892 17.9337C35.8988 18.1029 36.2762 18.3145 36.9052 18.5684C37.4922 18.78 37.8696 18.9916 38.0792 19.1186C38.7082 19.5841 39.0017 20.4305 39.0017 21.7424C39.0017 23.5198 38.6663 25.5511 37.9954 27.8786C37.3664 30.1638 36.4858 32.1528 35.3956 33.8456C34.3054 35.4537 32.754 37.1041 31.0348 38.2044C29.4414 39.347 27.5126 39.8549 25.3741 39.8549C22.9421 39.8549 21.139 39.0085 19.8811 37.4004C18.749 35.7499 18.3716 33.7609 18.3716 32.1528C18.3716 30.0369 18.6232 28.0479 19.2941 26.0166C19.7972 24.3661 20.3843 22.758 21.0971 21.1922C21.8099 19.6687 22.397 18.4415 22.8163 17.5105L24.1581 14.5905C24.4935 13.9557 24.829 13.1939 25.1225 13.1939C25.4579 13.1939 25.4579 14.0403 25.3322 14.3366C25.1225 14.8867 24.9128 15.3945 24.7451 15.7754C24.6193 16.1139 24.4097 16.6641 24.0742 17.3835C23.4872 18.78 22.6905 20.6421 22.1873 21.7424C21.0971 24.747 20.5101 26.9899 20.5101 28.4711C20.5101 31.645 22.9001 33.634 26.9255 33.634C28.6028 33.634 30.1962 33.2108 31.7476 32.4491C35.2279 30.587 37.0729 27.4977 37.0729 25.8896C37.0729 25.5087 36.8213 25.2548 36.2343 25.0856C35.2279 24.747 34.8925 24.747 34.3054 24.4085C33.0894 23.8583 32.5024 23.0543 32.5024 21.5731C32.5024 20.4305 32.8378 18.9916 33.5507 17.2142C34.3054 15.4792 35.3118 13.7441 36.6955 12.0513C37.7857 10.6971 38.7501 9.55452 39.6307 8.70814C40.5951 7.77712 42.2304 6.50755 43.6561 5.7035C44.4947 5.23799 45.2075 4.94175 45.7107 4.85712C46.0881 4.85712 46.2558 5.28031 46.1719 5.61886Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default SmallLogo;
