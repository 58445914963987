import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useConfig } from "../../context/config";
import Menu from "../menu";
import HeaderWrapper from "./styles";
import MenuWapper from "../menu/menuWrapper";
import SmallLogo from "../logo/smallLogo";
import i18n from "../../config/i18n";

function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const [albumId, setAlbumId] = useState(null);
  const { pathname } = useLocation();
  const [headerType, setHeaderType] = useState("home");
  const handleShowMenu = () => {
    setShowMenu(true);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const checkHeaderType = (path) => {
    let header;

    if (path === "/") {
      setHeaderType("home");
    } else {
      const lastPath = pathname.split("/")[pathname.split("/").length - 1];
      const last = Number(lastPath);
      if (!Number.isNaN(last)) {
        setAlbumId(last);
        setHeaderType("album");
      } else {
        setHeaderType("other");
      }
    }
  };

  const { state, actions } = useConfig();

  useEffect(() => {
    setShowMenu(false);
    checkHeaderType(pathname);
  }, [pathname]);

  useEffect(() => {
    console.log("lang => ", state.lang);
  }, []);

  const normalMenu = headerType === "home" || headerType === "album";

  return (
    <HeaderWrapper>
      <header
        className={`wrapper width-100 ${
          normalMenu ? "position-relative" : "position-absolute"
        }`}
      >
        <div className="wrapper_menu width-100">
          <div className="container">
            <div className="ds-flex justify-between align-center">
              <Menu isHome={normalMenu} showMenuWrapper={handleShowMenu} />
              {headerType !== "home" && (
                <Link to="/">
                  <SmallLogo
                    className={`wrapper_small-logo ${
                      headerType === "album" ? "wrapper_album-page" : ""
                    }`}
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </header>
      {showMenu && <MenuWapper closeMenu={handleCloseMenu} />}
    </HeaderWrapper>
  );
}

export default Header;
