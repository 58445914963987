import styled from "styled-components";

export default styled.div`
  .wrapper {
    border-radius: 10px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    border: 1px solid var(--gray-20);
    &:before {
      padding-top: 100.4%;
      display: block;
      content: "";
    }
    &_cover {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      border: 1px solid var(--gray-20);
    }
    &_body {
      padding: 24px 20px 24px 20px;
      font-family: RaviFanum;
      @media only screen and (max-width: 768px) {
        padding: 8px;
      }
    }
    &_title {
      font-size: 1.25rem;
      font-weight: 700;
      @media only screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }
    &_info {
      font-size: 1em;
      font-weight: 400;
      color: var(--gray-60);
      @media only screen and (max-width: 768px) {
        font-size: 0.75em;
      }
    }
    &_info-link {
      color: var(--primary-color);
    }
    &_play-mobile {
      color: var(--spotify-color);
      font-size: 0.875em;
      svg {
        width: 10px;
      }
    }
  }
`;
