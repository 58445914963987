// eslint-disable-next-line import/no-extraneous-dependencies
import { isMobile, isDesktop } from "react-device-detect";
import { useLocation } from "react-router-dom";
import Icon from "../Icon/Icon";
import HeaderWrapper from "./styles";
import AvailableOn from "./availableOn";

interface props {
  title: string;
  className?: string;
  icons?: string[];
}

function headerSection({ title, className, icons }) {
  const { pathname } = useLocation();
  return (
    <HeaderWrapper>
      <div
        className={`${className} wrapper ${
          pathname !== "/" && pathname !== "/albums" ? "not-home" : ""
        }`}
      >
        <div className="ds-flex justify-between align-center wrapper_sections flex-column">
          <div className="wrapper_section" />
          <div className="wrapper_section" />
          <div className="wrapper_section" />
          <div className="ds-flex wrapper_main">
            <div className="ds-flex align-center wrapper_circle">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="17" cy="17" r="5" />
                <circle cx="17" cy="31" r="3" />
                <circle cx="3" cy="17" r="3" />
                <circle cx="17" cy="3" r="3" />
                <circle cx="31" cy="17" r="3" />
              </svg>
            </div>
            <div className="wrapper_title">
              <Icon name={title} isHeader />
            </div>
            <div className="ds-flex align-center wrapper_circle">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="17" cy="17" r="5" fill="#2D3436" />
                <circle cx="17" cy="31" r="3" fill="#2D3436" />
                <circle cx="3" cy="17" r="3" fill="#2D3436" />
                <circle cx="17" cy="3" r="3" fill="#2D3436" />
                <circle cx="31" cy="17" r="3" fill="#2D3436" />
              </svg>
            </div>
          </div>
          <div />
          {icons && isDesktop && (
            <AvailableOn className="wrapper_available-on" items={icons} />
          )}
        </div>
      </div>
    </HeaderWrapper>
  );
}
headerSection.defaultProps = {
  title: null,
  className: null,
  icons: null,
};
export default headerSection;
