import { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useConfig } from "../../context/config";
import { useGetBioInfo, useGetRows } from "../../hooks/queries/home";
import BioWrapper from "./styles";
import LazyImage from "../lazyImage";
import Logo from "../logo";
import CustomLink from "../customLink";

interface props {
  data: any;
  className: string;
}

function Bio({ data, className }) {
  const [bioRightImage, setBioRightImage] = useState("");
  const [bioLeftImage, setBioLeftImage] = useState("");
  const [bioCenterImage, setBioCenterImage] = useState("");
  const [bio, setBio] = useState("");
  const [logo, setLogo] = useState("");
  const [moreBio, setMoreBio] = useState("");
  const { state, actions } = useConfig();
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setBioRightImage(data[3]?.acf?.image);
      setBioLeftImage(data[0]?.acf?.image);
      setBioCenterImage(data[2]?.acf?.image);
      setLogo(data[1]?.acf?.image);
      setBio(
        state.lang === "fa"
          ? data[3]?.content?.rendered
          : data[3]?.acf?.en_biography
      );
      setMoreBio(data[3]?.acf?.link);
    }
  }, [data, state.lang]);

  return (
    <BioWrapper>
      <div className={`${className} wrapper container ds-flex justify-between`}>
        {/* right-side bio */}
        {!isMobile && (
          <div className="wrapper_bio-right-side ds-flex flex-column">
            <div>
              <LazyImage
                className="wrapper_bio-img"
                onError={undefined}
                height="auto"
                width="100"
                alt="alighamsari-bio"
                src={bioRightImage}
                isLazy
              />
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: bio }}
              className="wrapper_bio-caption mt-5"
            />
            <Link className="mt-3 wrapper_bio-more" to={moreBio}>
              {t("bio.more")}
            </Link>
          </div>
        )}
        {/* center */}
        <div
          className={!isMobile ? `wrapper_bio-center` : `ds-flex flex-column`}
        >
          {isMobile && (
            <div className="ds-flex justify-center mb-3 wrapper_mobile-logo">
              <Logo />
            </div>
          )}
          <div className={!isMobile ? `` : `width-100`}>
            <LazyImage
              isLazy
              className="wrapper_bio-img"
              onError={undefined}
              height="auto"
              width="100"
              alt="alighamsari-bio"
              src={bioCenterImage}
            />
          </div>
          {isMobile && (
            <div className="wrapper_bio-right-side-mobile">
              <div
                dangerouslySetInnerHTML={{ __html: bio }}
                className="wrapper_bio-caption mt-5"
              />

              <Link
                className="mt-3 ds-flex justify-center width-100 wrapper_bio-more"
                to={moreBio}
              >
                {t("bio.more")}
              </Link>
            </div>
          )}
        </div>
        {/* left-side bio */}
        {!isMobile && (
          <div className="wrapper_bio-left-side ds-flex flex-column justify-between">
            <div className="ds-flex justify-center">
              <Logo />
            </div>
            <div>
              <LazyImage
                className="wrapper_bio-img"
                onError={undefined}
                height="auto"
                width="100"
                alt="alighamsari-bio"
                src={bioLeftImage}
                isLazy
              />
            </div>
          </div>
        )}
      </div>
    </BioWrapper>
  );
}

export default Bio;
