// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../button";
import CustomLink from "../../customLink";
import MenuWrapper from "./styles";
import { useConfig } from "../../../context/config";
import i18n from "../../../config/i18n";

interface props {
  closeMenu: () => void;
}

function MenuWapper({ closeMenu }) {
  const { state, actions } = useConfig();
  const { t } = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n?.changeLanguage(lang);
    actions.changeLang(lang);
    if (lang === "en") {
      document.body.classList.add("ltr");
    } else {
      document.body.classList.remove("ltr");
    }
    closeMenu();
  };

  return (
    <MenuWrapper>
      <div className="wrapper flex-column">
        <div className="wrapper_header ds-flex align-center">
          <Button
            iconStart="close"
            handleClick={closeMenu}
            className="wrapper_close"
          />
        </div>
        <div className="wrapper_body mt-5 ds-flex justify-center">
          <ul className="ds-flex justify-center flex-column align-center wrapper_menu">
            <li className="mt-2">
              <Link to="/">{t("menu.home")}</Link>
            </li>
            <li className="mt-2">
              <Link to="/iranian-tar">{t("menu.iranianTar")}</Link>
            </li>
            <li className="mt-2">
              <Link to="/albums">{t("menu.albums")}</Link>
            </li>
            <li className="mt-2">
              <Link to="/podcasts">{t("menu.podcast")}</Link>
            </li>
            <li className="mt-2">
              <Link to="/biography">{t("menu.bio")}</Link>
            </li>
            <li className="mt-2 en-temp">
              <Button
                className="wrapper_back"
                handleClick={() =>
                  changeLanguageHandler(state.lang === "fa" ? "en" : "fa")
                }
              >
                {t("menu.lang")}
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </MenuWrapper>
  );
}

export default MenuWapper;
