// eslint-disable-next-line import/no-extraneous-dependencies
import { isDesktop, isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import Slider from "react-slick";
import SectionWrapper from "./styles";
import Card from "../card/card";
import SectionHeader from "../sectionHeader";
import AlbumCard from "../card/albumCard";
import EventCard from "../card/eventCard";
import Icon from "../Icon/Icon";
import PodcastCard from "../card/podcastCard";
import { useConfig } from "../../context/config";

interface props {
  iconTitle?: string;
  data?: any;
  className?: string;
}

function Section({ iconTitle, data, className }) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 300,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const moreItemHandling = () => {
    let link;
    switch (iconTitle) {
      case "iranianTar":
        link = "/iranian-tar";
        break;
      case "podcast":
        link = "/podcasts";
        break;
      case "albums":
        link = "/albums";
        break;
      default:
        break;
    }

    return link;
  };

  const iconHandling = () => {
    let result = [];
    switch (iconTitle) {
      case "iranianTar":
        result = [
          { icon: "instagram", link: "https://www.instagram.com/alighamsari/" },
          { icon: "youtube", link: "https://www.youtube.com/@alighamsari" },
        ];
        break;
      case "podcast":
        result = [
          {
            icon: "spotify",
            link: "https://open.spotify.com/artist/3RGDkgJ2rGLnm4JqoEvdVT?si=Qa7O7K20SIeL4sjrdV8mxQ",
          },
          {
            icon: "castbox",
            link: "https://castbox.fm/channel/Parallel-Lines-|-خطوط-موازی-id5572536?utm_source=website&utm_medium=dlink&utm_campaign=web_share&utm_content=Parallel%20Lines%20%7C%20%D8%AE%D8%B7%D9%88%D8%B7%20%D9%85%D9%88%D8%A7%D8%B2%DB%8C-CastBox_FM",
          },
        ];
        break;
      case "albums":
        result = [
          {
            icon: "spotify",
            link: "https://open.spotify.com/artist/3RGDkgJ2rGLnm4JqoEvdVT?si=Qa7O7K20SIeL4sjrdV8mxQ",
          },
        ];
        break;
      case "events":
        result = [{ icon: "", link: "/" }];
        break;

      default:
        break;
    }
    return result;
  };
  const { state, actions } = useConfig();
  return (
    <SectionWrapper>
      <div className={`${className} wrapper`}>
        <div className="container">
          <SectionHeader
            title={iconTitle}
            className="wrapper_header"
            icons={iconTitle !== "events" ? iconHandling() : ""}
          />

          <div className="ds-flex  align-start mt-7 flex-wrap justify-between">
            {iconTitle === "iranianTar" &&
              data?.map((item) => (
                // <Card
                //   key={item?.acf?.image}
                //   title={item?.title?.rendered}
                //   description={item?.content?.rendered}
                //   externalLink={item?.acf?.external_link}
                //   link={item?.external_link}
                //   img={item?.acf?.image}
                //   className={`${isMobile ? "mb-4 col-1" : "col-3"}`}
                //   data={item}
                // />

                <Card
                  key={item.id}
                  title={
                    state.lang === "fa"
                      ? item?.title?.rendered
                      : item?.acf?.en_title
                  }
                  description={
                    state.lang === "fa"
                      ? item?.content?.rendered
                      : item?.acf?.english_description
                  }
                  externalLink={item?.acf?.external_link}
                  link={undefined}
                  img={item?.acf?.image}
                  className={`${isMobile ? "mb-4 col-1" : "col-3"}`}
                  data={item}
                />
              ))}
            {iconTitle === "podcast" &&
              data?.map((item) => (
                <PodcastCard
                  key={item?.acf?.image}
                  data={item}
                  className={`${isMobile ? "mb-4 col-1" : "col-2"}`}
                />
              ))}
            {iconTitle === "albums" &&
              data?.map((item) => (
                <AlbumCard
                  key={item?.acf?.image}
                  title={item?.title?.rendered}
                  link={item?.acf?.internal_link}
                  externalLink={item?.acf?.external_link}
                  cover={item?.acf?.image}
                  album={item?.acf?.album}
                  className={`${isMobile ? "mb-4 col-2" : "col-4"}`}
                />
              ))}
          </div>
          {iconTitle !== "events" && (
            <Link
              to={moreItemHandling()}
              className={`ds-flex justify-center align-center ${
                isDesktop ? "mt-7" : ""
              } wrapper_more-items`}
            >
              <span>مشاهده بیشتر</span>
              <Icon className="mr-1" name="arrowLeft" />
            </Link>
          )}
          {iconTitle === "events" && (
            <Slider {...settings}>
              {data?.map((item) => (
                <EventCard
                  key={item?.acf?.image}
                  data={item}
                  className="pl-2 pr-2"
                />
              ))}
            </Slider>
          )}
        </div>
      </div>
    </SectionWrapper>
  );
}

Section.defaultProps = {
  isPodcast: false,
  data: [],
  className: null,
};
export default Section;
