import styled from "styled-components";

export default styled.div`
  .wrapper {
    &_podcast-title {
      font-size: 1rem;
      color: var(--gray-60);
      margin-top: 60px;
    }
  }
`;
