import { createGlobalStyle, css } from "styled-components";
import "./globalVariables.css";

export default createGlobalStyle`

	body {
	  	padding: 0;
		margin: 0;
		background-color: var(--light);
		color: var(--dark);
		direction: rtl;
		font-family: 'ravi', 'Open Sans', sans-serif !important;
		font-size: 16px;
		}
	img {
		width: 100%;
	}
		.container {
			padding: 0 70px;
			margin: 0 auto;
			max-width: 1440px;
		}
		@media only screen and (min-width: 220px) {
			.container {
				padding: 0 15px;
				margin: 0 auto;
			}
		}
		@media only screen and (min-width: 768px) {
			.container {
				padding: 0 30px;
				margin: 0 auto;
			}
		}
	  @media only screen and (min-width: 1024px) {
		.container {
			padding: 0 70px;
			margin: 0 auto;
		}
	}
		@media only screen and (min-width: 1440px) {
			.container {
				padding: 0 50px;
				margin: 0 auto;
			}
	
	  }


	overflow-x: hidden;
	
	html {
		&[lang='fa'] {
			body {
				font: 14px 'IRANSans', "Open Sans", sans-serif;
				font-weight: 300;
			}
		}

		&[lang='en'] {
			body {
				font: 14px "Open Sans", sans-serif;
				font-weight: 300;
			}
		}
	}
	* {
		box-sizing: border-box;
		outline: 0;
	}

	button, a {
		cursor: pointer;
    	background: no-repeat;
    	border: 0;
	}

	a {
		text-decoration: none;
		color: inherit;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	h1, h2, h3, h4, h5, h6 {
		margin: 0;
		font-family: inherit;
		font-weight: 400;
	}

	.width-100 {
		width: 100%;
	}

	.ds-flex {
		display: flex;
	}
	.flex-wrap {
		flex-wrap : wrap;
	}
	.flex-column {
		flex-direction: column;
	}
	.justify-center {
		justify-content : center;
	}
	.justify-start {
		justify-content : flex-start;
	}
	.justify-end {
		justify-content : flex-end;
	}
	.justify-between {
		justify-content : space-between;
	}
	.justify-around {
		justify-content : space-around;
	}
	.align-center {
		align-items : center;
	}
	.align-start {
		align-items : flex-center;
	}
	.flex-center {
		display: flex;
		justify-content : center;
		align-items : center;
	}

	.pt-1 {
		padding-top: 5px;
	}
	.pt-2 {
		padding-top: 10px;
	}
	.pt-3 {
		padding-top: 15px;
	}
	.pt-4 {
		padding-top: 22px;
	}
	.pt-5 {
		padding-top: 15px;
	}

	.pb-1 {
		padding-bottom: 5px;
	}
	.pb-2 {
		padding-bottom: 10px;
	}
	.pb-3 {
		padding-bottom: 15px;
	}
	.pb-4 {
		padding-bottom: 20px;
	}
	.pb-5 {
		padding-bottom: 25px;
	}
	.pr-1 {
		padding-right: 5px;
	}
	.pr-2 {
		padding-right: 10px;
	}
	.pl-1 {
		padding-left: 5px;
	}
	.pl-2 {
		padding-left: 10px;
	}


	.mt-1 {
		margin-top: 5px;
	}
	.mt-2 {
		margin-top: 10px;
	}
	.mt-3 {
		margin-top: 15px;
	}
	.mt-5 {
		margin-top: 20px;
	}
	.mt-6 {
		margin-top: 25px;
	}
	.mt-7 {
		margin-top: 50px;
	}
	.mt-8 {
		margin-top: 35px;
	}
	.mt-9 {
		margin-top: 40px;
	}
	.mt-10 {
		margin-top: 45px;
	}
	.mb-1 {
		margin-bottom: 5px;
	}
	.mb-2 {
		margin-bottom: 10px;
	}
	.mb-3 {
		margin-bottom: 15px;
	}
	.mb-4 {
		margin-bottom: 20px;
	}
	.mb-5 {
		margin-bottom: 25px;
	}
	.mb-6 {
		margin-bottom: 50px;
	}
	.mb-7 {
		margin-bottom: 35px;
	}
	.mb-8 {
		margin-bottom: 40px;
	}
	.mb-9 {
		margin-bottom: 45px;
	}
	.mb-10 {
		margin-bottom: 45px;
	}
	.ml-1 {
		margin-left: 5px;
	}
	.ml-2 {
		margin-left: 10px;
	}
	.ml-3 {
		margin-left: 15px;
	}
	.ml-4 {
		margin-left: 20px;
	}
	.ml-5 {
		margin-left: 25px;
	}
	.ml-6 {
		margin-left: 30px;
	}
	.ml-7 {
		margin-left: 35px;
	}
	.ml-8 {
		margin-left: 40px;
	}
	.ml-9 {
		margin-left: 45px;
	}
	.ml-10 {
		margin-left: 50px;
	}
	.mr-1 {
		margin-right: 5px;
	}
	.mr-2 {
		margin-right: 10px;
	}

	.position-fixed {
		position: fixed;
	}
	.position-absolute {
		position: absolute;
	}
	//grids
	.col-1 {
		width: 100%;
		padding: 0 5px;
	}
	.col-2 {
		width: 50%;
		padding: 0 5px;
	}
	.col-3 {
		width: 33%;
		padding: 0 5px;
	}
	.col-4 {
		width: 25%;
		padding: 0 10px;
	}

`;
