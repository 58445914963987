import styled from "styled-components";

export default styled.div`
  .wrapper {
    position: relative;
    &_page-header {
      position: absolute;
      width: 100%;
      bottom: 50px;
      @media only screen and (max-width: 768px) {
        bottom: 20px;
      }
    }
  }
`;
