import Icon from "../Icon/Icon";
import LazyImage from "../lazyImage";
import ModalWrapper from "./style";

type Props = {
  src?: string;
  closeModal: () => void;
};

function Modal({ src, closeModal }) {
  const handleClose = () => closeModal();
  return (
    <ModalWrapper onClick={handleClose}>
      <div className="container">
        <button className="close" type="button" onClick={handleClose}>
          <Icon name="close" />
        </button>
        <LazyImage width={400} src={src} className="image-container" />
      </div>
    </ModalWrapper>
  );
}

Modal.defaultProps = {
  src: null,
  closeModal: null,
};

export default Modal;
