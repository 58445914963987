import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { useEffect } from "react";
import NewEvent from "../containers/pirouzoParizad";
import Bio from "../containers/bio";
import Admin from "../containers/admin";
import IranianTar from "../containers/iranianTar";
import Album from "../containers/albums/album";
import List from "../containers/albums";
import Footer from "../components/footer";
import Header from "../components/header";
import Home from "../containers/home";
import GlobalStyle from "../utils/globalStyles";
import NoMatch from "../containers/noMatch";
import Podcasts from "../containers/podcasts";
import ConfigStore, { useConfig } from "../context/config";
import i18n from "../config/i18n";
// eslint-disable-next-line import/no-extraneous-dependencies
import "slick-carousel/slick/slick.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import "slick-carousel/slick/slick-theme.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { state, actions } = useConfig();
  useEffect(() => {
    i18n?.changeLanguage(state.lang);
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigStore>
        <>
          <GlobalStyle />
          <BrowserRouter>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/podcasts" element={<Podcasts />} />
              <Route path="/albums" element={<List />} />
              <Route path="/albums/:id" element={<Album />} />
              <Route path="/iranian-tar" element={<IranianTar />} />
              <Route path="/biography" element={<Bio />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="*" element={<NoMatch />} />
              <Route path="/pirouzo-parizad" element={<NewEvent />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </>
      </ConfigStore>
    </QueryClientProvider>
  );
}

export default App;
