import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import getBio from "../../services/bio";

const useGetBio = (): UseMutationResult | any =>
  useMutation<AxiosResponse, AxiosError, string, () => void>(getBio);

export default useGetBio;
