import LazyImage from "../lazyImage";
import LoadingWrapper from "./styles";
import loading from "../../img/loading.gif";

interface Props {
  size?: string;
}
function Loading({ size }) {
  return (
    <LoadingWrapper>
      <div className={`wrapper ${size === "sm" ? "loading_sm" : "loading_lg"}`}>
        <div className="wrapper_loading">
          <LazyImage src={loading} isLazy />
        </div>
      </div>
    </LoadingWrapper>
  );
}
Loading.defaultProps = {
  size: "",
};

export default Loading;
