import styled from "styled-components";

export default styled.div`
  .wrapper {
    background: var(--gray-10);
    padding-top: 50px;
    padding-bottom: 50px;
    &_left-footer {
      font-size: 18px;
      @media only screen and (min-width: 220px) and (max-width: 1023px) {
        margin: 30px auto;
      }
    }
    &_left-footer-item {
      @media only screen and (min-width: 220px) and (max-width: 1023px) {
        flex-direction: column-reverse;
        margin-top: 20px;
        .wrapper_circle-icon {
          margin-bottom: 10px;
        }
      }
    }
    &_ltr {
      direction: ltr;
    }
    &_social {
      max-width: 320px;
      min-width: 400px;
      @media only screen and (min-width: 220px) and (max-width: 1023px) {
        max-width: 100%;
        min-width: 100%;
      }
    }
    &_circle-icon {
      background: var(--gray-20);
      border-radius: 100px;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
