import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import AvailableOn from "../../components/sectionHeader/availableOn";
import useGetIranianTar from "../../hooks/queries/iranianTar";
import Loading from "../../components/loading";
import Banner from "../../components/banner";
import iranianTarBanner from "../../img/banner/iranian-tar-banner.png";
import List from "./list";
import IranianTarHeader from "./header";
import Wrapper from "./styles";
import { useConfig } from "../../context/config";

function Podcasts() {
  const [iranianTar, setIranianTar] = useState([]);
  const [intro, setIntro] = useState([]);
  const { state, actions } = useConfig();
  const { t } = useTranslation();

  const {
    mutate: getIranianTar,
    data: iranianTarData,
    isLoading: isIranianTarLoading,
  } = useGetIranianTar();

  useEffect(() => {
    getIranianTar();
  }, []);

  useEffect(() => {
    const iranianTarList = [];
    const introduction = [];
    iranianTarData?.forEach((item) => {
      if (item?.acf?.introduction === false) {
        iranianTarList.push(item);
        setIranianTar(iranianTarList);
      } else {
        introduction.push(item);
        setIntro(introduction);
      }
    });
  }, [iranianTarData]);

  return (
    <Wrapper>
      <Helmet>
        <title>Iranian Tar</title>
      </Helmet>
      <Banner src={iranianTarBanner} title="iranianTar" />
      {isIranianTarLoading && <Loading />}
      {!isIranianTarLoading && (
        <div className="mt-5">
          <div className="container">
            {intro && (
              <div className="mt-10">
                <IranianTarHeader
                  className="mb-4"
                  description={
                    state.lang === "fa"
                      ? intro[0]?.content?.rendered
                      : intro[0]?.acf?.english_description
                  }
                />
              </div>
            )}
            {iranianTar && (
              <>
                <div className="width-100 ds-flex justify-between">
                  <h3 className="wrapper_podcast-title mb-3">
                    {t("iranianTar.AllVideos")}
                  </h3>
                  <AvailableOn
                    className="wrapper_available-on"
                    items={[
                      {
                        icon: "instagram",
                        link: "https://www.instagram.com/alighamsari",
                      },
                      {
                        icon: "youtube",
                        link: "https://www.youtube.com/channel/UCXxDTNEUWIYlkTBLCDTL30Q",
                      },
                    ]}
                  />
                </div>
                <List data={iranianTar} className="mt-6 mb-7" />
              </>
            )}
          </div>
        </div>
      )}
    </Wrapper>
  );
}

export default Podcasts;
