import { useState } from "react";
import Modal from "../../components/modal";
import CustomLink from "../../components/customLink";
import Banner from "../../components/banner";
import eventBanner from "../../img/banner/pirouzo-parizad.png";
import cover1 from "../../img/banner/cover-1.png";
import cover2 from "../../img/banner/cover-2.png";
import cover3 from "../../img/banner/cover-3.png";
import cover4 from "../../img/banner/cover-4.png";
import cover5 from "../../img/banner/cover-5.png";
import cover6 from "../../img/banner/cover-6.png";
import cover7 from "../../img/banner/cover-7.png";
import cover8 from "../../img/banner/cover-8.png";
import cover9 from "../../img/banner/cover-9.png";
import Poems from "../../img/banner/Poems.pdf";
import Info from "../../img/banner/info.pdf";
import Wrapper from "./styles";
import LazyImage from "../../components/lazyImage";

function newEvent() {
  const [lightBox, setLightBox] = useState(false);
  const [lightBoxSrc, setLightBoxSrc] = useState(null);

  const handleLightBox = (item) => {
    console.log("hi");
    setLightBox(true);
    setLightBoxSrc(item);
  };

  const closeModal = () => {
    setLightBox(false);
  };
  return (
    <Wrapper>
      <div>
        <Banner src={eventBanner} />
        <div className="container">
          <div className="mt-6 mb-5 ds-flex flex-column align-center width-100 justify-center info">
            <CustomLink target="_blank" link={Poems}>
              <span>مجموعه اشعار پیروز و پریزاد</span>
            </CustomLink>
            <br />
            <CustomLink target="_blank" link={Info}>
              <span>اطلاعات کنسرت</span>
            </CustomLink>
          </div>
          <div className="ds-flex justify-center gallery-container">
            <div className="gallery">
              <LazyImage onClick={() => handleLightBox(cover8)} src={cover8} />
            </div>
            <div className="gallery">
              <LazyImage onClick={() => handleLightBox(cover6)} src={cover6} />
            </div>
            <div className="gallery">
              <LazyImage onClick={() => handleLightBox(cover7)} src={cover7} />
            </div>
            <div className="gallery">
              <LazyImage onClick={() => handleLightBox(cover9)} src={cover9} />
            </div>
            <div className="gallery">
              <LazyImage onClick={() => handleLightBox(cover2)} src={cover2} />
            </div>
            <div className="gallery">
              <LazyImage onClick={() => handleLightBox(cover1)} src={cover1} />
            </div>
            <div className="gallery">
              <LazyImage onClick={() => handleLightBox(cover3)} src={cover3} />
            </div>
            <div className="gallery">
              <LazyImage onClick={() => handleLightBox(cover4)} src={cover4} />
            </div>
            <div className="gallery">
              <LazyImage onClick={() => handleLightBox(cover5)} src={cover5} />
            </div>
          </div>
        </div>
      </div>
      {/* lightbox modal */}
      {lightBox && <Modal closeModal={closeModal} src={lightBoxSrc} />}
    </Wrapper>
  );
}

export default newEvent;
