import styled from "styled-components";

export default styled.div`
  .wrapper {
    border-radius: 10px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    border: 1px solid var(--gray-20);
    &:before {
      padding-top: 100.4%;
      display: block;
      content: "";
    }
    &_cover {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      overflow: hidden;
      background: #bfbbb2;
      .LazyLoad {
        height: 100%;
      }
    }
    img {
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
    &_card-content {
      padding: 10px 10px 10px 10px;
      direction: rtl;
    }
    &_card-title {
      font-size: 1rem;
      font-weight: 700;
      font-family: RaviFanum;
      @media only screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }
    &_card-sub-title {
      font-size: 0.85rem;
      font-weight: bold;
      color: var(--gray-30);
    }
    &_card-description {
      font-size: 1rem;
      font-weight: 400;
      color: var(--gray-60);
      line-height: 25px;
      @media only screen and (max-width: 768px) {
        font-size: 0.875rem;
      }
    }
    &_card-link {
      font-size: 1rem;
      font-weight: 700;
      color: var(--primary-color);
      path,
      svg {
        fill: var(--primary-color);
        width: 30px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 0.875rem;
      }
    }
    &_active-status {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 15px;
      color: #fff;
      padding: 5px 5px;

      font-size: 0.75rem;
    }
    &_active-status-rtl {
      left: 0;
      border-top-right-radius: 10px;
    }
    &_active-status-ltr {
      right: 0;
      border-top-left-radius: 10px;
    }
    button {
      cursor: pointer;
      font-family: inherit;
      &:hover {
        color: var(--primary-color);
      }
    }
  }
`;
