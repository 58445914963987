import styled from "styled-components";

export default styled.div`
  .wrapper {
    &_more-items {
      color: var(--primary-color);
      svg,
      path {
        fill: var(--primary-color);
        width: 30px;
      }
    }
  }
`;
