import styled from "styled-components";

export default styled.div`
  .wrapper {
    &_bio-more {
      color : var(--primary-color);
    }
    &_bio-right-side-mobile {
      line-height: 28px;
      @media only screen and (min-width: 220px) and (max-width: 768px) {
        text-align: center;
      }
    }
    &_mobile-logo {
      @media only screen and (max-width: 768px) {
        svg {
          width: 165px;
        }
      }
    }
    &_bio-left-side {
      width: 24%;
      padding-right: 1%;
    }
    &_bio-right-side {
      width: 24%;
      padding-left: 1%;
      line-height: 28px;
    }
    &_bio-center {
      width: 49%;
      padding-right: 1%;
      padding-left: 1%;
    }
    &_bio-img {
      border-radius: 16px;
    }
    &_bio-caption {
      p {
        padding: 0;
        margin: 0;
        text-align: justify:
      }
    }
  }
`;
