import LazyLoad from "react-lazy-load";
import LazyImageWrapper from "./styles";

interface Props {
  className?: string;
  isLazy?: boolean;
  alt: string;
  src: string;
  width?: string;
  height?: string;
  onClick?: () => void;
  onError?: (currentTarget) => void;
}

function LazyImage({
  className,
  isLazy,
  alt,
  src,
  width,
  height,
  onError,
  onClick,
}) {
  if (isLazy) {
    return (
      <LazyLoad offset={100}>
        <LazyImageWrapper
          className={className}
          src={src}
          alt={!alt ? "loading" : alt}
          width={width}
          height={height}
          onError={({ currentTarget }) => {
            if (onError && currentTarget) onError(currentTarget);
          }}
          onClick={onClick}
        />
      </LazyLoad>
    );
  }

  return (
    <LazyImageWrapper
      className={className}
      loading="lazy"
      src={src}
      rel="preload"
      alt={alt}
      width={width}
      height={height}
      onError={({ currentTarget }) => {
        if (onError && currentTarget) onError(currentTarget);
      }}
      onClick={onClick}
    />
  );
}

LazyImage.defaultProps = {
  className: "",
  isLazy: true,
  alt: "",
  src: "",
  width: "",
  height: "",
  onError: null,
  onClick: null,
};

export default LazyImage;
