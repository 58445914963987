import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../Icon/Icon";
import CustomLink from "../../customLink";
import LazyImage from "../../lazyImage";
import CardWrapper from "./styles";
import Modal from "./modal";
import { useConfig } from "../../../context/config";

interface props {
  title?: string;
  description?: string;
  externalLink?: string;
  link?: string;
  img?: string;
  className?: string;
  data?: any;
}

function Card({
  title,
  description,
  externalLink,
  link,
  img,
  className,
  data,
}) {
  const [longDescription, setLongDescription] = useState(null);

  const handleLongDescriptionModalClose = () => {
    setLongDescription(false);
    document.body.removeEventListener("click", handleLongDescriptionModalClose);
  };
  const handleLongDescriptionModal = () => {
    setLongDescription(true);
    setTimeout(() => {
      document.body.addEventListener("click", handleLongDescriptionModalClose);
    }, 1000);
  };

  const { t } = useTranslation();
  const { state, actions } = useConfig();

  return (
    <CardWrapper className={`${className}`}>
      <div className="wrapper width-100">
        <div className="wrapper_cover">
          <LazyImage src={img} alt={title} isLazy />
        </div>
        <div className="wrapper_content">
          <div
            className="wrapper_title"
            dangerouslySetInnerHTML={{
              __html: `${title}`,
            }}
          />
          <div
            className="wrapper_description"
            dangerouslySetInnerHTML={{
              __html:
                description?.length > 10
                  ? `${description?.substring(0, 100)}...`
                  : description,
            }}
          />
          <div
            className={`ds-flex align-center justify-between wrapper_more-info${
              state.lang === "fa" ? "" : "-ltr"
            }`}
          >
            <button type="button" onClick={() => handleLongDescriptionModal()}>
              {t("card.iranianTar.info")}
            </button>
            <CustomLink
              className="wrapper_link ds-flex"
              link={externalLink}
              target="_blank"
            >
              <span>{t("card.iranianTar.play")}</span>
              <Icon name="playRed" />
            </CustomLink>
          </div>
        </div>
      </div>
      {longDescription === true && (
        <Modal
          title={title}
          data={data}
          className={undefined}
          closeModal={handleLongDescriptionModalClose}
        />
      )}
    </CardWrapper>
  );
}

export default Card;
