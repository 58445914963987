import { isDesktop } from "react-device-detect";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../context/config";
import Icon from "../Icon/Icon";
import FooterWrapper from "./styles";

function Footer() {
  const { state, actions } = useConfig();
  const { t } = useTranslation();
  const marginClass = state.lang === "fa" ? "ml-2" : "mr-2";
  return (
    <FooterWrapper>
      <div className="wrapper mt-5 pt-3">
        <div
          className={`container ds-flex justify-between ${
            !isDesktop ? "flex-wrap" : ""
          }`}
        >
          <div className="wrapper_right-footer wrapper_social">
            <div className="ds-flex align-center justify-between mb-3">
              <span>{t("footer.albums")}</span>
              <a
                target="_blank"
                className="ds-flex align-center"
                href="https://open.spotify.com/artist/3RGDkgJ2rGLnm4JqoEvdVT?si=u-pj-9iqRH28_-kdzSKrww"
                rel="noreferrer"
              >
                <span className={marginClass}>Spotify</span>
                <Icon name="spotify" />
              </a>
            </div>
            <div className="ds-flex align-center justify-between mb-3">
              <span>{t("footer.videos")}</span>
              <a
                href="https://www.youtube.com/@alighamsari"
                target="_blank"
                className="ds-flex align-center ml-4"
                rel="noreferrer"
              >
                <span className={marginClass}>Youtube</span>
                <Icon name="youtube" />
              </a>
              <a
                href="https://www.instagram.com/alighamsari/"
                target="_blank"
                className="ds-flex align-center justify-between"
                rel="noreferrer"
              >
                <span className={marginClass}>Instagram</span>
                <Icon name="instagram" />
              </a>
            </div>
            <div className="ds-flex align-center justify-between mb-3">
              <span>{t("footer.podcasts")}</span>
              <a
                href="https://castbox.fm/vc/5572513"
                target="_blank"
                rel="noreferrer"
                className="align-center ds-flex"
              >
                <span className={marginClass}>Castbox</span>
                <Icon name="castbox" />
              </a>
              <a
                href="https://open.spotify.com/artist/3RGDkgJ2rGLnm4JqoEvdVT?si=u-pj-9iqRH28_-kdzSKrww"
                target="_blank"
                rel="noreferrer"
                className="align-center ds-flex"
              >
                <span className={marginClass}>Spotify</span>
                <Icon name="spotify" />
              </a>
            </div>
          </div>
          <div className="wrapper_left-footer ds-flex flex-column justify-center">
            <div className="ds-flex align-center justify-between mb-3 wrapper_left-footer-item">
              <span className={marginClass}>ghamsariali.music@gmail.com</span>
              <div className="wrapper_circle-icon">
                <Icon name="email" />
              </div>
            </div>
            <div className="ds-flex align-center justify-end mb-3 wrapper_left-footer-item">
              <span className={`${marginClass} wrapper_ltr`}>
                +98 919 911 6719
              </span>
              <div className="wrapper_circle-icon">
                <Icon name="phone" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
}

export default Footer;
