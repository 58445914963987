import styled from "styled-components";

export default styled.div`
  .wrapper-album {
    margin-top: 100px;
    &_list {
      margin-bottom: 150px;
    }
    &_header-list {
      margin-bottom: 70px;
    }
  }
`;
