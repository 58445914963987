import Icon from "../Icon/Icon";
import StyledButton from "./styles";

interface props {
  size?: "small" | "large";
  color?: string;
  text?: React.ReactNode | string;
  iconStart?: string;
  iconEnd?: string;
  circular?: boolean;
  rounded?: boolean;
  bordered?: boolean;
  fullWidth?: boolean;
  quiet?: boolean;
  id?: string;
  className?: string;
  href?: string;
  target?: string;
  type?: string | any;
  disableRipple?: boolean;
  onClick?: (e: MouseEvent) => void;
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
}

function Button({
  type,
  children,
  size,
  color,
  id,
  circular,
  rounded,
  quiet,
  className,
  fullWidth,
  routeLink,
  target,
  href,
  bordered,
  handleClick,
  iconStart,
  iconEnd,
  disabled,
  loading,
  rest,
}) {
  return (
    <StyledButton
      href={!routeLink ? href : null}
      target={target}
      type={!routeLink && href ? null : type}
      id={id}
      className={`button${className ? ` ${className}` : ""}`}
      size={size}
      fullWidth={fullWidth}
      color={color}
      circular={circular}
      rounded={rounded}
      quiet={quiet}
      bordered={bordered}
      onClick={handleClick}
      disabled={disabled || loading}
      {...rest}
    >
      {children}
      {iconStart && (
        <div className="ds-flex align-center">
          <Icon name={iconStart} />
        </div>
      )}
      {iconEnd && (
        <div className="ds-flex align-center">
          <Icon name={iconEnd} />
        </div>
      )}
    </StyledButton>
  );
}

Button.defaultProps = {
  type: "button",
  size: "medium",
  color: "primary",
  circular: false,
  rounded: true,
  bordered: false,
  quiet: false,
  fullWidth: false,
  id: null,
  className: null,
  href: null,
  target: null,
  loading: false,
  disabled: false,
  children: null,
  routeLink: null,
  iconStart: null,
  iconEnd: null,
  rest: null,
};

export default Button;
