import styled from "styled-components";

export default styled.div`
  .wrapper {
    background-color: var(--light);
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    align-items: center;
    top: 0;
    &_loading {
      width: 120px;
      height: 120px;
    }
  }
`;
