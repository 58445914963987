import Axios from "axios";
import ENDPOINTS from "../config/endpoints";

const getAlbums = async () => {
  const { data } = await Axios.get(ENDPOINTS.albums.albums);
  return data;
};

const getAlbum = async (albumId) => {
  const { data } = await Axios.get(ENDPOINTS.albums.album(albumId));
  return data;
};

const getAlbumTracks = async () => {
  const { data } = await Axios.get(ENDPOINTS.albums.tracks);
  return data;
};

export { getAlbums, getAlbum, getAlbumTracks };
