import styled from "styled-components";

export default styled.div`
  .modal-wrapper {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    &_close {
      font-family: inherit;
      svg {
        width: 15px;
        height: 15px;
        margin-left: 5px;
      }
    }
    &_cover {
      width: 100px;
      margin-left: 10px;
    }
    &_content {
      background: var(--light);
      width: 50%;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px;
      border-radius: 16px;
      max-height: 600px;
      overflow-y: scroll;
      @media only screen and (max-width: 1023px) {
        width: 80%;
      }
    }
    &_footer {
      font-size: 0.75rem;
    }
    &_title {
      color: var(--gray-80);
      font-size: 1.25rem;
    }
    &_date {
      color: var(--gray-60);
      font-size: 0.75rem;
      margin-top: 5px;
    }
    &_description {
      color: var(--gray-80);
      font-size: 1rem;
      margin-top: 5px;
    }
  }
`;
