import styled from "styled-components";

export default styled.div`
  .wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    &_available-on {
      position: absolute;
      left: 0;
      background: var(--light);
      color: var(--gray-60);
      font-size: 16px;
      top: 50%;
      left: 0%;
      svg {
        width: auto !important;
      }
      path {
        fill: var(--gray-60) !important;
      }
    }
    &_header {
      margin-bottom: 100px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 70px;
      }
    }

    svg {
      width: 145px;
      @media only screen and (min-width: 220px) and (max-width: 767px) {
        width: 80px;
      }
    }
    &_circle {
      @media only screen and (min-width: 220px) and (max-width: 767px) {
        svg {
          width: 25px;
        }
      }
    }
    path,
    circle {
      fill: #2d3436;
    }
    &_sections {
      position: relative;
      width: 100%;
    }
    &_section {
      border-bottom: 1px solid rgba(45, 52, 54, 0.1);
      width: 100%;
      height: 20px;
      @media only screen and (min-width: 220px) and (max-width: 767px) {
        height: 10px;
      }
    }
    &_main {
      position: absolute;
      top: -70%;
      display: flex;
      align-items: center;
      @media only screen and (min-width: 220px) and (max-width: 767px) {
        top: -80%;
      }
    }
    &_title {
      margin: 0 40px;
    }
  }

  .not-home {
    .wrapper_section {
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }
    path,
    circle {
      fill: var(--light);
    }
  }
`;
