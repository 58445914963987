import styled from "styled-components";

export default styled.div`
  .wrapper {
    &_card {
      width: 49%;
      margin-bottom: 45px;
      @media only screen and (max-width: 1023px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
`;
