import styled from "styled-components";

export default styled.img`
  display: block;
  top: 50%;
  left: 50%;
  // width: 100%;
  font-size: 0;
  // max-height: none;
  // min-height: 100%;
  // max-width: 100%;
`;
