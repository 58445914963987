import styled from "styled-components";

export default styled.div`
  .wrapper {
    border: 1px solid var(--gray-20);
    border-radius: 16px;
    font-family: RaviFanum;
    padding: 20px 24px 20px 24px;
    @media only screen and (max-width: 768px) {
      padding: 16px;
    }
    &_content {
    }
    &_cover {
      margin-left: 24px;
    }
    &_title {
      font-size: 1.25rem;
      color: var(--gray-80);
      font-weight: 700;
      @media only screen and (max-width: 768px) {
        font-size: 0.875rem;
      }
    }
    &_description {
      font-size: 1rem;
      @media only screen and (max-width: 768px) {
        font-size: 0.875rem;
      }
      color: var(--gray-60);
      font-weight: 400;
    }
    &_more {
      align-self: center;
      font-size: 1.25rem;
      color: var(--orange);
      @media only screen and (max-width: 768px) {
        font-size: 0.875rem;
        svg {
          width: 25px;
        }
      }
    }
    &_content {
      width: 70%;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;
