import styled from "styled-components";

export default styled.div`
  .wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 45px;
    padding-bottom: 25px;
    z-index: 2;
    &_small-logo {
      @media only screen and (max-width: 768px) {
        svg {
          width: 61px !important;
        }
      }
    }
    &_album-page {
      path,
      svg {
        fill: var(--primary-color);
      }
      line {
        stroke: var(--primary-color);
      }
    }
    @media only screen and (max-width: 768px) {
      padding-top: 20px;
      padding-bottom: 0px;
      svg {
        width: 24px;
        height: auto;
      }
      .not-home {
        svg {
          width: 25px;
        }
      }
    }
  }
`;
