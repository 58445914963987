import StyledLink from "./styles";

interface props {
  children?: React.ReactNode;
  link: string;
  target?: "_blank" | "_self";
  className?: string;
  onClick?: (e: any) => void;
}

function CustomLink({ children, link, className, target, onClick }: props) {
  return (
    <StyledLink
      href={link}
      className={className}
      target={target}
      onClick={() => onClick}
    >
      {children}
    </StyledLink>
  );
}

CustomLink.defaultProps = {
  children: "",
  target: "_self",
  className: "",
  onClick: "",
};
export default CustomLink;
