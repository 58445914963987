import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import getIranianTar from "../../services/iranianTar";

const useGetIranianTar = (): UseMutationResult | any =>
  useMutation<AxiosResponse, AxiosError, string, () => void>(getIranianTar);

export default useGetIranianTar;
