import { log } from "console";
import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import Loading from "../../../components/loading";
import Icon from "../../../components/Icon/Icon";
import Button from "../../../components/button";
import LazyImage from "../../../components/lazyImage";
import { useGetAlbum, useGetAlbums, useGetTracks } from "../../../hooks/albums";
import List from "../list";
import AlbumWrapper from "./styles";
import useConfig from "../../../context/config";

function Album() {
  const params = useParams();
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };

  const {
    mutate: getAlbum,
    data: albumData,
    isLoading: isAlbumLoading,
  } = useGetAlbum();

  const {
    mutate: getTracks,
    data: tracksData,
    isLoading: isTracksLoading,
  } = useGetTracks();

  useEffect(() => {
    getAlbum(params.id);
    getTracks();
  }, []);

  const { id } = useParams();

  return (
    <AlbumWrapper>
      {(isAlbumLoading || isTracksLoading) && <Loading />}
      {!isAlbumLoading && !isTracksLoading && (
        <div className="wrapper mt-10">
          <div className="container">
            <div
              className={`wrapper_album-intro ds-flex justify-between align-center ${
                !isDesktop ? "flex-wrap" : ""
              }`}
            >
              {!isDesktop && (
                <Button
                  iconStart="arrowRight"
                  handleClick={back}
                  className="wrapper_back ds-flex"
                >
                  بازگشت
                </Button>
              )}
              <div className="wrapper_album-cover">
                <LazyImage src={albumData?.acf?.cover} isLazy />
              </div>
              <div className="wrapper_album-intro-content">
                {isDesktop && (
                  <Button
                    iconEnd="arrowLeft"
                    handleClick={back}
                    className="wrapper_back ds-flex align-center"
                  >
                    بازگشت
                  </Button>
                )}
                <div>
                  <h3 className="wrapper_album-title">
                    {albumData?.title?.rendered}
                  </h3>
                  <div
                    className="wrapper_album-description"
                    dangerouslySetInnerHTML={{
                      __html: albumData?.content?.rendered,
                    }}
                  />
                  <div className="ds-flex justify-between">
                    <Link
                      to="/"
                      className="ds-flex wrapper_play-link align-center justify-between"
                    >
                      <Icon name="playGreen" className="wrapper_circle ml-1" />
                      <span>بشنوید</span>
                    </Link>
                    {/* <Link to="/" className="wrapper_buy-album">
                      خرید البوم
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper_more-albums mt-10">
              <h3 className="wrapper_second-title">قطعات آلبوم</h3>
              <List
                listTitle={undefined}
                className={undefined}
                data={tracksData}
              />
            </div>
          </div>
        </div>
      )}
    </AlbumWrapper>
  );
}

export default Album;
