import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "fa",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          artist: {
            name: "Ali Ghamsari",
          },
          bio: {
            more: "Read More",
          },
          menu: {
            home: "Home",
            iranianTar: "Iranian Tar",
            albums: "Albums",
            podcast: "Podcast",
            bio: "Biography",
            lang: "فارسی",
          },
          footer: {
            albums: "Albums",
            videos: "Videos",
            podcasts: "Podcasts",
          },
          card: {
            event: {
              ended: "Finished",
              current: "On performing",
              more: "More Info",
            },
            iranianTar: {
              play: "play",
              info: "More Info",
            },
          },
          modal: {
            close: "close",
            play: "play",
          },
          iranianTar: {
            allVideos: "All Videos",
          },
        },
      },
      fa: {
        translation: {
          artist: {
            name: "علی قمصری",
          },
          bio: {
            more: "بیشتر بخوانید",
          },
          menu: {
            home: "خانه",
            iranianTar: "تار ایرانی",
            albums: "آثار صوتی",
            podcast: "پادکست",
            bio: "زندگینامه",
            lang: "english",
          },
          footer: {
            albums: "آثار صوتی در",
            videos: "آثار ویدیویی در",
            podcasts: "پادکست در",
          },
          card: {
            event: {
              ended: "به اتمام رسیده",
              active: "در حال برگزاری",
              soon: "بزودی",
              more: "اطلاعات بیشتر",
            },
            iranianTar: {
              play: "پخش",
              info: "مشاهده توضیحات",
            },
          },
          modal: {
            close: "بستن توضیحات",
            play: "پخش این قسمت",
          },
          iranianTar: {
            AllVideos: "تمام ویدیوهای تار ایرانی",
          },
        },
      },
    },
  });

export default i18n;
