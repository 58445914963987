import styled from "styled-components";

export default styled.div`
  .wrapper {
    font-family: RaviFanum;
    padding: 20px 24px 20px 24px;
    @media only screen and (max-width: 768px) {
      padding: 0;
    }
    &_description {
      width: 75%;
      font-size: 1rem;
      color: var(--gray-60);
      font-weight: 400;
      text-align: center;
      line-height: 30px;
      @media only screen and (max-width: 768px) {
        font-size: 0.875rem;
        width: 100%;
      }
    }
  }
`;
