import { isMobile } from "react-device-detect";
import PodcastCard from "../../../components/card/podcastCard";
import Wrapper from "./styles";

interface props {
  className: string;
  data: any;
}

function List({ className, data }) {
  return (
    <Wrapper>
      <div className={`wrapper ${className}`}>
        <div className="ds-flex mt-5 justify-between flex-wrap">
          {data?.map((card) => (
            <PodcastCard
              key={card.slug}
              data={card}
              className={`${isMobile ? "mb-4 col-1" : "col-2"} wrapper_card`}
            />
          ))}
        </div>
      </div>
    </Wrapper>
  );
}

export default List;
