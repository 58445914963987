import styled from "styled-components";

export default styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  .close {
    position: absolute;
    left: 15px;
    top: 15px;
  }
  .image-container {
    border-radius: 5px;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;
