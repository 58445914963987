import { useEffect, useState } from "react";
import Loading from "../../components/loading";
import { useGetAlbums } from "../../hooks/albums";
import Banner from "../../components/banner";
import List from "./list";
import albumBanner from "../../img/banner/albums-banner.png";
import AlbumWrapper from "./styles";

function Albums() {
  const [albums, setAlbums] = useState([]);
  const [singles, setSingles] = useState([]);

  const {
    mutate: getAlbums,
    data: albumsData,
    isLoading: isAlbumsLoading,
  } = useGetAlbums();

  useEffect(() => {
    getAlbums();
  }, []);

  useEffect(() => {
    const albumList = [];
    const singleList = [];
    albumsData?.forEach((item) => {
      if (item.acf.album) {
        albumList.push(item);
        setAlbums(albumList);
      } else {
        singleList.push(item);
        setSingles(singleList);
      }
    });
  }, [albumsData]);

  return (
    <AlbumWrapper>
      <Banner src={albumBanner} />
      {isAlbumsLoading && <Loading />}
      {!isAlbumsLoading && (
        <div className="wrapper-album">
          <div className="container">
            {albums && (
              <List
                data={albums}
                listTitle="albumHead"
                className="wrapper-album_list"
              />
            )}
            {singles && (
              <List
                data={singles}
                listTitle="singleHead"
                className="wrapper-album_list"
              />
            )}
          </div>
        </div>
      )}
    </AlbumWrapper>
  );
}

export default Albums;
