import styled from "styled-components";

export default styled.div`
  .wrapper {
    &_podcast-title {
      font-size: 16px;
      color: var(--gray-60);
    }
  }
`;
