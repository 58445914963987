import Icon from "../Icon/Icon";
import MenuWrapper from "./styles";

interface props {
  showMenuWrapper: () => void;
  isHome: boolean;
}

function Menu({ showMenuWrapper, isHome }) {
  return (
    <MenuWrapper>
      <button
        type="button"
        onClick={showMenuWrapper}
        className={!isHome ? "not-home" : ""}
      >
        <Icon name="menu" />
      </button>
    </MenuWrapper>
  );
}

export default Menu;
