import styled from "styled-components";

export default styled.div`
  .wrapper {
    .en-temp {
      display: none;
    }
    background: rgba(255, 255, 255, 0.93);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: flex-start;
    padding: 3%;
    z-index: 2;
    .button {
      cursor: pointer;
      &:hover {
        color: var(--primary-color);
      }
    }

    &_close {
      cursor: pointer;
    }
    &_body {
      height: 100%;
    }
    &_menu {
      font-size: 1.5rem;
      @media only screen and (max-width: 768px) {
        font-size: 1.25rem;
      }
      a {
        color: var(--dark-color);
      }
      a:hover {
        color: var(--primary-color);
      }
    }
  }
`;
