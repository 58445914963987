import Icon from "../../../Icon/Icon";
import CustomLink from "../../../customLink";
import LazyImage from "../../../lazyImage";
import ModalWrapper from "./styles";

interface props {
  title?: string;
  data?: any;
  className?: string;
  closeModal: () => void;
}
function Modal({ title, data, className, closeModal }) {
  const handleClose = () => closeModal();

  return (
    <ModalWrapper className={`${className}`}>
      <div className="modal-wrapper" id="modal-podcast">
        <div className="modal-wrapper_content">
          <div className="modal-wrapper_header ds-flex">
            <div className="modal-wrapper_cover">
              <LazyImage
                isLazy
                src={data?.acf?.image}
                width="100"
                alt={data?.title?.rendered}
              />
            </div>
            <div>
              <div className="modal-wrapper_title">{title}</div>
              <div className="modal-wrapper_date width-100 ds-flex">
                <span className="ml-2">{data?.acf?.podcast_upload_date}</span>
                <span>{data?.acf?.podcast_duration}</span>
              </div>
            </div>
          </div>
          <div className="modal-wrapper_main">
            <div
              className="wrapper_description"
              dangerouslySetInnerHTML={{
                __html: data?.content?.rendered,
              }}
            />
            <div className="ds-flex justify-between mt-6 modal-wrapper_footer align-center">
              <button
                className="modal-wrapper_close ds-flex align-center justify-between"
                type="button"
                onClick={handleClose}
              >
                <Icon name="close" />
                بستن توضیحات
              </button>
              <CustomLink
                target="_blank"
                className="wrapper_link ds-flex align-center"
                link={data?.acf?.play_link}
              >
                <div>پخش این قسمت</div>
                <Icon name="playOrange" />
              </CustomLink>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default Modal;
