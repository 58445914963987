const ENDPOINTS = {
  home: {
    bio: "https://www.alighamsarimusic.com/manager/wp-json/wp/v2/images",
    rows: "https://www.alighamsarimusic.com/manager/wp-json/wp/v2/posts/?per_page=100",
  },
  albums: {
    albums:
      "https://www.alighamsarimusic.com/manager/wp-json/wp/v2/albums/?per_page=100",
    album: (id) =>
      `https://www.alighamsarimusic.com/manager/wp-json/wp/v2/albums/${id}`,
    tracks: `https://www.alighamsarimusic.com/manager/wp-json/wp/v2/tracks/?per_page=100`,
  },
  podcasts: {
    podcasts: "https://www.alighamsarimusic.com/manager/wp-json/wp/v2/podcasts",
  },
  iranianTar: {
    iranianTar:
      "https://www.alighamsarimusic.com/manager/wp-json/wp/v2/iranian_tar",
  },
  biography: {
    bio: "https://www.alighamsarimusic.com/manager/wp-json/wp/v2/biography",
  },
};
export default ENDPOINTS;
