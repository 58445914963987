import { isDesktop, isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import Icon from "../../Icon/Icon";
import CustomLink from "../../customLink";
import LazyImage from "../../lazyImage";
import CardWrapper from "./styles";

interface props {
  title: string;
  album?: boolean;
  link?: string;
  externalLink?: string;
  className?: string;
  release?: string;
  cover: string;
  track: boolean;
}

function AlbumCard({
  title,
  album,
  link,
  externalLink,
  className,
  release,
  cover,
  track,
}) {
  return (
    <CardWrapper className={className}>
      <div className={`wrapper ds-flex flex-column ${isMobile ? "mb-4" : ""}`}>
        <div className="wrapper_cover">
          <LazyImage src={cover} isLazy />
        </div>
        <div className="wrapper_body width-100">
          <div className="ds-flex justify-between align-center">
            <span className="wrapper_title">{title}</span>
            {isDesktop && (
              <CustomLink target="_blank" link={externalLink}>
                <Icon name="play" />
              </CustomLink>
            )}
          </div>

          <div className="mt-3 ds-flex justify-between align-center">
            <div className="ds-flex align-center">
              {isDesktop && (
                <span className="ml-1">
                  {!album ? <Icon name="singleTrack" /> : <Icon name="album" />}
                </span>
              )}
              <span className="wrapper_info ml-1">
                {track ? "تک آهنگ" : "آلبوم"}
              </span>
              <span className="wrapper_info">{release}</span>
            </div>
            {!track && (
              <Link
                className="wrapper_info wrapper_info-link wrapper_info"
                to={link}
              >
                {isDesktop ? "توضیحات آلبوم" : "توضیحات"}
              </Link>
            )}
          </div>

          {!isDesktop && (
            <CustomLink
              className="ds-flex mt-2 align-center wrapper_play-mobile"
              target="_blank"
              link={externalLink}
            >
              <span className="ml-2">پخش</span>
              <Icon name="play" />
            </CustomLink>
          )}
        </div>
      </div>
    </CardWrapper>
  );
}
AlbumCard.defaultProps = {
  title: null,
  album: null,
  link: null,
  externalLink: null,
  className: null,
  release: null,
  cover: null,
  track: null,
};
export default AlbumCard;
