import styled from "styled-components";

export default styled.div`
  .section {
    margin-top: 170px;
    margin-bottom: 150px;
    @media only screen and (min-width: 220px) and (max-width: 1023px) {
      margin-bottom: 75px;
      margin-top: 50px;
    }
  }
`;
