import styled from "styled-components";

export default styled.div`
  .wrapper {
    border: 1px solid var(--gray-20);
    border-radius: 10px;
    overflow: hidden;
    cursor: default;
    button {
      font-family: inherit;
    }
    &_content {
      padding: 20px 24px 20px 20px;
      width: 70%;
      @media only screen and (max-width: 1023px) {
        padding-right: 12px;
        padding-left: 0;
      }
    }
    &_cover {
      width: 35%;
    }
    &_title {
      font-size: 1.25rem;
      font-weight: 700;
      @media only screen and (max-width: 1023px) {
        font-size: 1rem;
      }
    }
    &_description {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 25px;
      color: var(--gray-60);
      min-height: 95px;
      @media only screen and (max-width: 1023px) {
        font-size: 0.75rem;
      }
    }
    &_link {
      color: var(--orange);
    }
    &_text {
      @media only screen and (max-width: 1023px) {
        font-size: 0.875em;
      }
    }
    &_info {
      font-size: 1rem;
      button {
        color: var(--primary-color);
        font-size: inherit;
      }
      @media only screen and (max-width: 1023px) {
        font-size: 0.875rem;
      }
    }
  }
`;
