import { Link } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import Icon from "../../../components/Icon/Icon";
import HeaderWrapper from "./styles";
import LazyImage from "../../../components/lazyImage";

interface props {
  cover: string;
  title: string;
  description: string;
  more: string;
}

function PodcastHeader({ cover, title, description, more }) {
  return (
    <HeaderWrapper>
      <div
        className={`wrapper ds-flex align-center justify-between ${
          !isDesktop ? "flex-column" : ""
        }`}
      >
        <div className="ds-flex align-center wrapper_content">
          <div className="wrapper_cover">
            <LazyImage isLazy src={cover} width="100px" height="100px" />
          </div>
          <div className="ds-flex justify-around flex-column wrapper_description">
            <h3 className="wrapper_title">{title}</h3>
            {!isDesktop && (
              <div className="wrapper_more">
                <Link
                  to={more}
                  target="_blank"
                  className="ds-flex align-center"
                >
                  <span>عضویت در پادکست</span>
                  <Icon name="arrowLeft" />
                </Link>
              </div>
            )}
            {isDesktop && (
              <div
                className="mb-3"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
          </div>
        </div>
        {isDesktop && (
          <div className="wrapper_more">
            <Link target="_blank" to={more} className="ds-flex align-center">
              <span>عضویت در پادکست</span>
              <Icon name="arrowLeft" />
            </Link>
          </div>
        )}
        {!isDesktop && (
          <div
            className="wrapper_description"
            dangerouslySetInnerHTML={{
              __html:
                description?.length > 10
                  ? `${description?.substring(0, 120)}...`
                  : description,
            }}
          />
        )}
      </div>
    </HeaderWrapper>
  );
}

export default PodcastHeader;
