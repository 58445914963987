import HeaderWrapper from "./styles";

interface props {
  description: string;
  className?: string;
}

function IranianTarHeader({ description, className }) {
  return (
    <HeaderWrapper>
      <div
        className={`${className} wrapper ds-flex align-center justify-center`}
      >
        <div
          className="wrapper_description mb-3"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    </HeaderWrapper>
  );
}

export default IranianTarHeader;
