import { log } from "console";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import useGetPodcasts from "../../hooks/queries/podcasts";
import Loading from "../../components/loading";
import Banner from "../../components/banner";
import podcastBanner from "../../img/banner/podcast-banner.png";
import List from "./list";
import PodcastHeader from "./podcastHeader";
import Wrapper from "./styles";

function Podcasts() {
  const [podcasts, setPodcasts] = useState([]);
  const [intro, setIntro] = useState([]);

  const {
    mutate: getPodcasts,
    data: podcastsData,
    isLoading: isPodcastsLoading,
  } = useGetPodcasts();

  useEffect(() => {
    getPodcasts();
  }, []);

  useEffect(() => {
    const podcastList = [];
    const introduction = [];
    podcastsData?.forEach((item) => {
      if (item?.acf?.Introduction === true) {
        introduction.push(item);
        setIntro(introduction);
      } else {
        podcastList.push(item);
        setPodcasts(podcastList);
      }
    });
  }, [podcastsData]);

  return (
    <Wrapper>
      <Helmet>
        <title>Podcasts</title>
      </Helmet>
      <Banner src={podcastBanner} title="podcast" />
      {isPodcastsLoading && <Loading />}
      {!isPodcastsLoading && (
        <div className="mt-5">
          <div className="container">
            {intro && (
              <div className="mt-10">
                <PodcastHeader
                  cover={intro[0]?.acf?.image}
                  title={intro[0]?.title?.rendered}
                  description={intro[0]?.content?.rendered}
                  more={intro[0]?.acf?.play_link}
                />
              </div>
            )}
            {podcasts && (
              <>
                <h3 className="wrapper_podcast-title">تمام قسمت های پادکست</h3>
                <List data={podcasts} className="mt-6 mb-7" />
              </>
            )}
          </div>
        </div>
      )}
    </Wrapper>
  );
}

export default Podcasts;
