import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { getRows, getBioInfo } from "../../services/home";

const useGetRows = (): UseMutationResult | any =>
  useMutation<AxiosResponse, AxiosError, string, () => void>(getRows);

const useGetBioInfo = (): UseMutationResult | any =>
  useMutation<AxiosResponse, AxiosError, string, () => void>(getBioInfo);

export { useGetRows, useGetBioInfo };
