import styled from "styled-components";

export default styled.div`
  .wrapper {
    &_bio-left-side {
      width: 24%;
      padding-right: 1%;
    }
    &_bio-right-side {
      width: 24%;
      padding-left: 1%;
    }
    &_bio-center {
      width: 49%;
      padding-right: 1%;
      padding-left: 1%;
    }
    &_bio-img {
      border-radius: 16px;
    }
    &_bio-caption {
      p {
        padding: 0;
        margin: 0;
      }
    }
  }
`;
