import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { getAlbums, getAlbum, getAlbumTracks } from "../services/albums";

const useGetAlbums = (): UseMutationResult | any =>
  useMutation<AxiosResponse, AxiosError, string, () => void>(getAlbums);

const useGetAlbum = (): UseMutationResult | any =>
  useMutation<AxiosResponse, AxiosError, string, () => void>(getAlbum);

const useGetTracks = (): UseMutationResult | any =>
  useMutation<AxiosResponse, AxiosError, string, () => void>(getAlbumTracks);

export { useGetAlbums, useGetAlbum, useGetTracks };
