function Logo() {
  return (
    <svg
      width="100%"
      viewBox="0 0 302 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.24">
        <line y1="94" x2="302" y2="94" stroke="#0067A5" />
        <line y1="124" x2="302" y2="124" stroke="#0067A5" />
        <line y1="154" x2="302" y2="154" stroke="#0067A5" />
        <line y1="184" x2="302" y2="184" stroke="#0067A5" />
        <line y1="214" x2="302" y2="214" stroke="#0067A5" />
      </g>
      <path
        d="M211.984 178.054C226.856 178.795 237.458 164.131 244.231 152.726C246.293 149.023 248.06 144.876 249.532 140.581C250.71 136.878 249.532 136.878 247.471 139.84C244.82 143.099 242.464 147.838 238.636 150.505C235.396 152.874 230.832 152.726 227.887 149.912C224.795 147.098 225.678 142.506 228.034 139.84C232.893 134.508 236.133 134.804 242.317 136.137C245.556 136.73 247.471 132.731 246.293 130.509C244.231 126.362 238.93 126.806 235.396 127.991C229.654 129.916 226.267 135.545 223.911 140.433C221.702 144.728 220.083 149.023 218.169 153.615C217.285 155.837 217.727 156.577 215.518 156.577C208.598 156.577 204.769 152.43 204.769 139.988C202.266 145.32 199.026 150.801 196.082 156.281C196.082 170.352 201.53 177.61 211.984 178.054Z"
        fill="#0067A5"
      />
      <path
        d="M204.769 139.988C205.211 130.212 207.125 117.623 208.45 108.143C209.186 102.515 207.419 104.885 206.241 107.699C203.297 114.068 199.91 120.881 199.026 127.546C197.407 137.174 196.229 152.726 196.229 156.281C196.229 162.798 191.664 168.575 184.154 183.831C181.21 189.755 178.265 197.605 185.038 202.345C186.658 203.382 188.719 204.567 190.928 206.196C196.818 209.603 195.787 212.269 192.989 217.453C187.099 228.117 175.761 233.894 163.982 234.634C138.508 236.264 134.974 216.268 142.925 195.532C146.754 185.164 150.288 176.277 154.852 166.205C157.061 161.317 155.441 161.169 152.791 165.76C145.576 178.202 138.803 191.681 134.091 205.752C127.759 225.007 126.876 255.815 155 257C167.368 257 177.234 250.187 185.332 241.3C192.106 233.746 197.112 224.414 200.646 213.306C203.886 203.382 208.156 191.236 198.585 183.682C193.578 179.831 191.811 178.202 195.198 171.833C196.523 169.463 198.143 167.39 199.615 164.872C201.235 160.873 204.475 146.653 204.769 139.988Z"
        fill="#0067A5"
      />
      <path
        d="M158.314 65.7604C158.314 66.2047 159.934 67.5378 163.321 69.9076C164.352 70.6482 165.235 71.5369 166.119 72.2775C167.296 73.0181 168.18 73.4624 168.769 73.4624C170.978 73.4624 174.07 69.9076 178.046 62.9462C179.665 63.983 181.579 65.4641 184.083 67.6859C185.702 69.3152 187.028 70.0557 187.764 70.0557C188.942 70.0557 190.856 68.4265 193.359 65.0198C196.01 61.6131 197.335 58.6508 197.335 56.2809C197.335 54.0592 196.01 51.8374 193.359 49.4676C190.856 47.2458 188.795 46.0609 187.028 46.0609C186.586 46.0609 185.261 47.6902 183.199 50.8006C181.579 53.0224 180.107 55.096 178.782 57.3177C177.898 55.6885 176.279 54.0592 173.775 52.2818C171.272 50.5044 169.505 49.6157 168.327 49.6157C167.591 49.6157 165.824 52.1337 162.879 57.1696C159.787 62.0575 158.314 65.0198 158.314 65.7604Z"
        fill="#0067A5"
      />
      <path
        d="M182.528 94.6306C178.487 87.1119 171.993 85.3914 166.908 93.2865C165.891 94.9248 165.319 96.8554 164.16 99.0874C163.586 100.722 162.421 102.213 161.846 103.848C159.546 110.385 163.416 115.091 170.022 112.811C171.638 112.353 171.491 112.354 172.533 113.678C174.766 116.473 173.898 118.258 171.562 120.5C166.89 125.133 164.268 128.415 162.957 130.055C160.772 132.889 159.168 134.68 157.583 138.694L165.644 134.327C166.064 131.806 170.01 128.364 173.519 125.519C174.836 124.471 176.3 123.569 177.615 122.373C181.849 118.188 184.447 112.092 184.837 106.164C185.096 102.014 184.326 98.1698 182.528 94.6306Z"
        fill="#0067A5"
      />
      <path
        d="M165.644 134.328C162.091 132.137 160.201 134.968 157.583 138.694C152.469 143.33 147.796 147.815 143.868 153.33C135.726 165.251 125.473 171.563 118.132 174.145C115.792 175.943 110.553 183.099 118.34 181.105C134.05 175.783 139.743 170.104 148.186 158.773C151.388 154.449 153.427 151.913 162.901 157.607C165.565 159.213 166.444 158.612 168.194 156.672C170.237 154.58 173.001 150.704 173.722 148.921C174.589 146.987 174.73 146.246 173.095 144.483C171.311 142.573 169.088 140.963 167.451 138.903C166.408 137.58 165.811 136.548 165.644 134.328Z"
        fill="#0067A5"
      />
      <path
        d="M118.34 181.105C124.342 177.053 129.44 170.639 133.502 163.494C138.143 155.306 144.787 140.436 133.145 139.353C125.625 138.234 119.056 144.809 114.985 150.77C111.785 155.39 108.734 160.156 105.683 164.923C104.375 166.86 104.669 166.857 102.31 166.434C94.0589 165.914 86.8698 168.939 80.5796 173.734C75.4608 177.779 70.7864 182.115 66.8546 187.186C62.1943 193.152 58.2766 199.852 54.2104 206.405C44.0384 222.047 32.4146 240.071 16.4527 250.284C7.0794 256.143 6.51998 259.555 18.3938 253.377C35.3993 244.637 48.7964 227.337 59.1117 211.25C66.814 199.48 76.4137 185.769 92.4719 186.665C103.815 187.158 111.46 185.757 118.34 181.105ZM110.394 164.734C112.287 162.347 113.884 159.667 115.777 157.28C118.548 154.145 121.465 150.861 126.03 150.969C127.357 151.106 128.684 151.39 130.311 152.116C131.935 152.695 133.413 153.274 132.839 155.057C132.265 156.691 131.099 158.182 129.932 159.378C124.825 164.755 118.662 167.327 111.592 167.093C108.204 166.975 108.5 167.12 110.394 164.734Z"
        fill="#0067A5"
      />
      <path
        d="M162.139 19.6661C161.844 21.4435 161.402 22.9246 160.813 24.5539C160.372 26.0351 159.341 28.4049 158.016 31.2192C156.691 34.0334 155.954 35.6626 155.66 35.9589C154.629 37.7363 153.746 38.4769 152.862 38.4769C151.684 37.7363 150.801 36.8476 150.064 36.8476C146.678 36.8476 140.935 39.3655 134.309 45.4383C127.83 51.5111 123.855 57.4357 123.855 59.3612C123.855 60.9905 124.296 62.0273 125.327 62.7679C126.063 63.3604 127.388 64.101 129.597 64.9897C131.659 65.7302 132.984 66.4708 133.72 66.9152C135.929 68.5444 136.96 71.5068 136.96 76.0984C136.96 82.3192 135.782 89.4288 133.426 97.5752C131.217 105.573 128.125 112.535 124.296 118.46C120.468 124.088 115.02 129.865 108.983 133.716C103.387 137.715 96.6139 139.492 89.1044 139.492C80.564 139.492 74.2324 136.53 69.815 130.901C65.8394 125.125 64.5142 118.163 64.5142 112.535C64.5142 105.129 65.3976 98.1677 67.7536 91.0581C69.5205 85.2816 71.582 79.6532 74.0852 74.1729C76.5884 68.8407 78.6498 64.5453 80.1223 61.2867L84.8342 51.0667C86.0122 48.845 87.1901 46.1789 88.2209 46.1789C89.3988 46.1789 89.3988 49.1412 88.9571 50.178C88.2209 52.1035 87.4846 53.8809 86.8957 55.214C86.4539 56.3989 85.7177 58.3244 84.5397 60.8424C82.4783 65.7302 79.6806 72.2473 77.9136 76.0984C74.0852 86.6146 72.0237 94.4648 72.0237 99.6488C72.0237 110.758 80.4168 117.719 94.5525 117.719C100.442 117.719 106.038 116.238 111.486 113.572C123.707 107.055 130.186 96.2422 130.186 90.6138C130.186 89.2807 129.303 88.392 127.241 87.7995C123.707 86.6146 122.529 86.6146 120.468 85.4297C116.198 83.5042 114.136 80.69 114.136 75.5059C114.136 71.5068 115.314 66.4708 117.817 60.2499C120.468 54.1772 124.002 48.1044 128.861 42.1798C132.689 37.44 136.076 33.4409 139.168 30.4786C142.555 27.22 148.298 22.7765 153.304 19.9623C156.249 18.333 158.752 17.2962 160.519 17C161.844 17 162.433 18.4812 162.139 19.6661Z"
        fill="#0067A5"
      />
    </svg>
  );
}

export default Logo;
