import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import i18n from "../../config/i18n";
import { useConfig } from "../../context/config";
import Loading from "../../components/loading";
import { useGetBioInfo, useGetRows } from "../../hooks/queries/home";
import Section from "../../components/section";
import Bio from "../../components/bio";
import SectionWrapper from "./styles";

function Home() {
  const [rows, setrows] = useState([]);
  const [bio, setBio] = useState(null);
  const [iranianTarData, setIranianTarData] = useState([]);
  const [podcastData, setPodcastData] = useState([]);
  const [albumsData, setAlbumsData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    mutate: getRows,
    data: rowsData,
    isLoading: isRowsLoading,
  } = useGetRows();
  const { mutate: getBio, data: bioData, isBioLoading } = useGetBioInfo();
  useEffect(() => {
    getBio();
    getRows();
  }, []);
  useEffect(() => {
    if (rowsData) {
      setrows(rowsData);
    }
  }, [rowsData]);

  useEffect(() => {
    setBio(bioData);
  }, [bioData]);

  useEffect(() => {
    if (bioData && rowsData) {
      setIsLoading(false);
    }
  }, [bioData, rowsData]);

  useEffect(() => {
    const iranianTar = [];
    const podcasts = [];
    const albums = [];
    const events = [];

    rows?.forEach((item) => {
      if (item?.categories?.includes(4)) {
        iranianTar.push(item);
      }
      setIranianTarData(iranianTar);

      if (item?.categories?.includes(5)) {
        podcasts.push(item);
      }
      setPodcastData(podcasts);

      if (item?.categories?.includes(10)) {
        albums.push(item);
      }
      setAlbumsData(albums);

      if (item?.categories?.includes(11)) {
        events.push(item);
      }
      setEventsData(events);
    });
  }, [rows]);

  const { state, actions } = useConfig();

  return (
    <SectionWrapper>
      <Helmet>
        <title>Ali Ghamsari Official web site</title>
        <link rel="canonical" href="https://alighamsarimusic.com/" />
      </Helmet>
      {isLoading && <Loading size="sm" />}
      {!isLoading && (
        <>
          <Bio data={bio} className="mb-10" />
          <Section
            iconTitle="iranianTar"
            data={iranianTarData}
            className="section"
          />
          <Section className="section" iconTitle="albums" data={albumsData} />
          <Section className="section" iconTitle="podcast" data={podcastData} />
          <Section className="section" iconTitle="events" data={eventsData} />
        </>
      )}
    </SectionWrapper>
  );
}

export default Home;
