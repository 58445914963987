function NoMatch() {
  return (
    <div style={{ padding: 20 }}>
      <h2>404: Page Not Found</h2>
      <p>صفحه مورد نظر پیدا نشد</p>
    </div>
  );
}

export default NoMatch;
