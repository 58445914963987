import { stat } from "fs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../../Icon/Icon";
import CustomLink from "../../../customLink";
import LazyImage from "../../../lazyImage";
import ModalWrapper from "./styles";
import { useConfig } from "../../../../context/config";

interface props {
  title?: string;
  data?: any;
  className?: string;
  closeModal: () => void;
}
function Modal({ title, data, className, closeModal }) {
  const handleClose = () => closeModal();
  const { state, actions } = useConfig();
  const { t } = useTranslation();
  return (
    <ModalWrapper className={`${className}`}>
      <div className="modal-wrapper" id="modal-podcast">
        <div className="modal-wrapper_content">
          <div className="modal-wrapper_header ds-flex">
            <div
              className={`modal-wrapper_cover ${
                state.lang !== "fa" ? "modal-wrapper_cover-ltr" : ""
              }`}
            >
              <LazyImage
                src={data?.acf?.image}
                width="100"
                alt={data?.title?.rendered}
                isLazy
              />
            </div>
            <div>
              <div
                className="modal-wrapper_title"
                dangerouslySetInnerHTML={{
                  __html: `${title}`,
                }}
              />
              <div className="modal-wrapper_date width-100 ds-flex">
                <span className="ml-2">{data?.acf?.sdate}</span>
                <span>{data?.acf?.duration}</span>
              </div>
            </div>
          </div>
          <div className="modal-wrapper_main">
            <div
              className="wrapper_description"
              dangerouslySetInnerHTML={{
                __html:
                  state.lang === "fa"
                    ? data?.content?.rendered
                    : data?.acf?.english_description,
              }}
            />
            <div
              className={`ds-flex justify-between mt-6 modal-wrapper_footer ${
                state.lang === "en" ? "modal-wrapper_footer-ltr" : ""
              }  align-center`}
            >
              <button
                className="modal-wrapper_close  ds-flex align-center justify-between"
                type="button"
                onClick={handleClose}
              >
                <Icon name="close" />
                {t("modal.close")}
              </button>
              <CustomLink
                target="_blank"
                className="wrapper_link ds-flex align-center"
                link={data?.acf?.external_link}
              >
                <div>{t("modal.play")}</div>
                <Icon name="playOrange" />
              </CustomLink>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default Modal;
