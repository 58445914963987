import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import getPodcasts from "../../services/podcasts";

const useGetPodcasts = (): UseMutationResult | any =>
  useMutation<AxiosResponse, AxiosError, string, () => void>(getPodcasts);

export default useGetPodcasts;
