import React, { useContext, useReducer } from "react";

const CHANGE_LANG = "CHANGE_LANG";

const initialStateValue = {
  lang: "fa",
};

const defaultConfigValues: any = {
  state: initialStateValue,
  actions: {},
};

const ConfigContext = React.createContext(defaultConfigValues);

const ConfigReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_LANG:
      return {
        ...state,
        lang: action.lang,
      };

    default:
      return state;
  }
};

function ConfigStore({ children, initislState = initialStateValue }) {
  const [state, dispatch] = useReducer(ConfigReducer, initislState);

  const changeLang = (lang) => {
    dispatch({
      type: CHANGE_LANG,
      lang,
    });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = {
    state,
    actions: {
      changeLang,
    },
  };

  return (
    <ConfigContext.Provider value={values}>{children}</ConfigContext.Provider>
  );
}

const useConfig = () => {
  const context = useContext(ConfigContext);
  return context;
};

export { useConfig };
export default ConfigStore;
