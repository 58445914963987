import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Admin() {
  const location = useLocation();

  useEffect(() => {
    window.location.replace("http://www.alighamsarimusic.com/manager");
  }, []);

  return <div>admin</div>;
}

export default Admin;
