import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import CustomLink from "../../customLink";
import Icon from "../../Icon/Icon";
import { useConfig } from "../../../context/config";
import LazyImage from "../../lazyImage";
import CardWrapper from "./styles";
import Modal from "./modal";

interface props {
  data: any;
  className: any;
}

function EventCard({ data, className }) {
  const { state, actions } = useConfig();
  const { t } = useTranslation();
  const [longDescription, setLongDescription] = useState(null);
  const handleLongDescriptionModalClose = () => {
    setLongDescription(false);
    document.body.removeEventListener("click", handleLongDescriptionModalClose);
  };
  const handleLongDescriptionModal = () => {
    setLongDescription(true);
    setTimeout(() => {
      document.body.addEventListener("click", handleLongDescriptionModalClose);
    }, 1000);
  };
  return (
    <CardWrapper className={className}>
      <div className={`wrapper ds-flex flex-column ${isMobile ? "mb-4" : ""}`}>
        <div className="wrapper_cover">
          <div
            className={`wrapper_active-status ${
              state.lang === "en"
                ? "wrapper_active-status-ltr"
                : "wrapper_active-status-rtl"
            }`}
          >
            {data?.acf?.eventـstatus?.value === "soon" && (
              <span>{t("card.event.soon")}</span>
            )}
            {data?.acf?.eventـstatus?.value === "active" && (
              <span>{t("card.event.active")}</span>
            )}
            {data?.acf?.eventـstatus?.value === "ended" && (
              <span>{t("card.event.ended")}</span>
            )}
            {data?.acf?.event_status}
          </div>
          <LazyImage src={data?.acf?.image} isLazy />
        </div>
        <div className="wrapper_card-content">
          <h3 className="wrapper_card-title mb-1">{data?.title?.rendered}</h3>
          <div className="justify-between ds-flex align-center">
            <span className="wrapper_card-sub-title">
              {data?.acf?.event_date}
            </span>
            {/* <div
            className="wrapper_card-description mb-3"
            dangerouslySetInnerHTML={{
              __html:
                data?.content?.rendered.length > 10
                  ? `${data?.content?.rendered.substring(0, 140)}...`
                  : data?.content?.rendered,
            }}
          /> */}

            {data?.acf?.link && (
              <Link className="wrapper_card-link" to={data?.acf?.link}>
                {t("card.event.more")}
              </Link>
            )}
            <CustomLink
              target="_blank"
              className="wrapper_card-link ds-flex align-center"
              link={data?.acf?.ticket}
            >
              <div>اطلاعات بیشتر</div>
              <Icon name="arrowLeft" />
            </CustomLink>
          </div>
        </div>
      </div>
      {/* {longDescription === true && (
        <Modal
          title={data?.title?.rendered}
          data={data}
          className=""
          closeModal={handleLongDescriptionModalClose}
        />
      )} */}
    </CardWrapper>
  );
}

export default EventCard;
