import { isDesktop, isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import AlbumCard from "../../../components/card/albumCard";
import SectionHeader from "../../../components/sectionHeader";
import AlbumWrapper from "../styles";

interface props {
  listTitle: string;
  className: string;
  data: any;
}

function List({ listTitle, className, data }) {
  const params = useParams();

  const renderedList = () => {
    const result = [];
    data?.forEach((card) => {
      if (card?.acf?.album?.value === params.id) {
        result.push(card);
      }
    });
    return result;
  };

  return (
    <AlbumWrapper>
      <div className={`${className}`}>
        {listTitle && (
          <SectionHeader
            title={listTitle}
            className="wrapper-album_header-list"
          />
        )}
        <div
          className={`ds-flex mt-10 justify-${
            isDesktop ? "start" : "around"
          } flex-wrap`}
        >
          {renderedList()?.map((card) => (
            <AlbumCard
              key={card?.slug}
              title={card?.title.rendered}
              album={card?.acf?.album}
              link={`/albums/${card.id}`}
              externalLink={card?.acf?.external_link}
              release={card?.acf?.release}
              cover={card?.acf?.cover}
              className={`${isMobile ? "mb-4 col-2" : "mb-7 col-4"}`}
              track={card?.acf?.track}
            />
          ))}
        </div>
      </div>
    </AlbumWrapper>
  );
}

export default List;
