import LazyImage from "../lazyImage";
import SectionHeader from "../sectionHeader";
import BannerWrapper from "./styles";

interface props {
  src: string;
  title?: string;
}

function Banner({ src, title }) {
  return (
    <BannerWrapper>
      <div className="wrapper">
        <LazyImage src={src} />
        {title && (
          <div className="wrapper_page-header">
            <SectionHeader title={title} />
          </div>
        )}
      </div>
    </BannerWrapper>
  );
}
Banner.defaultProps = {
  title: null,
};
export default Banner;
