import styled from "styled-components";

export default styled.div`
  .wrapper {
    .container {
      padding: 0 12%;
    }
    &_main-bio {
      margin-top: 50px;
    }
    &_bio-content {
      margin-top: 50px;
    }
    &_title {
      color: var(--gray-80);
      margin-bottom: 30px;
      font-size: 2rem;
      font-weight: bold;
    }
    &_description {
      font-size: 1.25rem;
      color: var(--gray-80);
      line-height: 36px;
      @media only screen and (max-width: 1024px) {
        font-size: 1rem;
      }
    }
    &_images {
      width: 25%;
      img {
        // width: auto;
      }
    }
    &_content {
      @media only screen and (min-width: 1024px) {
        width: 65%;
      }
    }
    &_bio-img {
      margin-bottom: 40px;
    }
    &_description-part,
    &_description-part,
    &_description-part,
    &_description-part {
      margin-bottom: 30px;
    }
  }
`;
