import styled from "styled-components";

export default styled.div`
  .wrapper {
    border: 1px solid var(--gray-20);
    border-radius: 10px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    &:before {
      padding-top: 62.5%;
      display: block;
      content: "";
    }
    &_cover {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }
    &_content {
      padding: 20px;
      padding-top: 0;
    }
    &_title {
      font-size: 1.25rem;
      font-weight: bold;
      @media only screen and (min-width: 220px) and (max-width: 768px) {
        font-size: 0.875rem;
      }
    }
    &_description {
      font-size: 1rem;
      color: var(--gray-60);
      line-height: 25px;
      @media only screen and (min-width: 220px) and (max-width: 768px) {
        font-size: 0.875rem;
      }
    }
    &_link {
      color: red;
    }
    &_more-info {
      font-size: 1rem;
      @media only screen and (min-width: 220px) and (max-width: 768px) {
        font-size: 0.875em;
      }
    }
    &_more-info-ltr {
      svg {
        transform: rotate(180deg);
      }
      button {
        font-size: 1.1rem;
      }
    }
    button {
      font-family: inherit;
      color: var(--primary-color);
    }
  }
`;
