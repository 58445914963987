import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { useConfig } from "../../context/config";
import useGetBio from "../../hooks/queries/bio";
import Loading from "../../components/loading";
import Banner from "../../components/banner";
import bioBanner from "../../img/banner/bio-banner.png";
import Wrapper from "./styles";
import LazyImage from "../../components/lazyImage";

function Bio() {
  const { state, actions } = useConfig();
  const [bio, setBio] = useState(null);
  const { t } = useTranslation();
  const {
    mutate: getBiography,
    data: biographyData,
    isLoading: isBiographyLoading,
  } = useGetBio();

  useEffect(() => {
    getBiography();
  }, []);

  useEffect(() => {
    setBio(biographyData);
  }, [biographyData]);

  return (
    <Wrapper>
      <Helmet>
        <title>Ali Ghamsari Biography</title>
        <link rel="canonical" href="https://alighamsarimusic.com/" />
      </Helmet>
      <Banner src={bioBanner} title="bio" />
      {isBiographyLoading && <Loading />}
      {!isBiographyLoading && (
        <div className="wrapper wrapper_bio-content">
          <div className="container mt-4">
            <h3 className="wrapper_title">{t("artist.name")}</h3>
            <div className="wrapper_description wrapper_intro">
              {state.lang === "fa"
                ? bio?.[0]?.acf?.intro_1
                : bio?.[0]?.acf?.intro_en_1}
            </div>
            <div className="wrapper_description wrapper_intro">
              {state.lang === "fa"
                ? bio?.[0]?.acf?.intro_2
                : bio?.[0]?.acf?.intro_en_2}
            </div>
            <div className="ds-flex wrapper_main-bio justify-between">
              {!isMobile && (
                <div className="ds-flex flex-column wrapper_images">
                  <div className="wrapper_bio-img">
                    <LazyImage
                      isLazy
                      alt="alighamsari-bio"
                      src={bio?.[0]?.acf?.image_1}
                    />
                  </div>
                  <div className="wrapper_bio-img">
                    <LazyImage
                      isLazy
                      alt="alighamsari-bio"
                      src={bio?.[0]?.acf?.image_2}
                    />
                  </div>
                  <div className="wrapper_bio-img">
                    <LazyImage
                      isLazy
                      alt="alighamsari-bio"
                      src={bio?.[0]?.acf?.image_3}
                    />
                  </div>
                  <div className="wrapper_bio-img">
                    <LazyImage
                      isLazy
                      alt="alighamsari-bio"
                      src={bio?.[0]?.acf?.image_4}
                    />
                  </div>
                </div>
              )}
              <div className="ds-flex flex-column wrapper_description wrapper_content">
                {isMobile && (
                  <div className="wrapper_bio-img">
                    <LazyImage
                      isLazy
                      alt="alighamsari-bio"
                      className="mt-3"
                      src={bio?.[0]?.acf?.image_1}
                    />
                  </div>
                )}
                <div className="wrapper_description-part">
                  <div>
                    {state.lang === "fa"
                      ? bio?.[0]?.acf?.part_2
                      : bio?.[0]?.acf?.part_en_2}
                  </div>
                </div>

                <div className="wrapper_description-part">
                  {state.lang === "fa"
                    ? bio?.[0]?.acf?.part_3
                    : bio?.[0]?.acf?.part_en_3}
                </div>
                <div className="wrapper_description-part">
                  {state.lang === "fa"
                    ? bio?.[0]?.acf?.part_4
                    : bio?.[0]?.acf?.part_en_4}
                </div>
                {isMobile && (
                  <div className="wrapper_bio-img">
                    <LazyImage
                      isLazy
                      alt="alighamsari-bio"
                      src={bio?.[0]?.acf?.image_2}
                    />
                  </div>
                )}
                <div className="wrapper_description-part">
                  {state.lang === "fa"
                    ? bio?.[0]?.acf?.part_5
                    : bio?.[0]?.acf?.part_en_5}
                </div>

                <div className="wrapper_description-part">
                  {state.lang === "fa"
                    ? bio?.[0]?.acf?.part_6
                    : bio?.[0]?.acf?.part_en_6}
                </div>
                {isMobile && (
                  <div className="wrapper_bio-img">
                    <LazyImage
                      isLazy
                      alt="alighamsari-bio"
                      src={bio?.[0]?.acf?.image_3}
                    />
                  </div>
                )}
                <div className="wrapper_description-part">
                  {state.lang === "fa"
                    ? bio?.[0]?.acf?.part_7
                    : bio?.[0]?.acf?.part_en_7}
                </div>
                <div className="wrapper_description-part">
                  {state.lang === "fa"
                    ? bio?.[0]?.acf?.part_8
                    : bio?.[0]?.acf?.part_en_8}
                </div>
                {isMobile && (
                  <div className="wrapper_bio-img">
                    <LazyImage
                      isLazy
                      alt="alighamsari-bio"
                      src={bio?.[0]?.acf?.image_4}
                    />
                  </div>
                )}
                <div className="wrapper_description-part">
                  {state.lang === "fa"
                    ? bio?.[0]?.acf?.part_9
                    : bio?.[0]?.acf?.part_en_9}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

export default Bio;
